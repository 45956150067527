<template>
	<div class="planDetail app_flex" :style="isApp ? 'padding-top: 1.3rem;' : ''">
		<div class="app_title_b">
			<van-sticky>
				<van-nav-bar :title="oPlanDetail.isCanCopy ? '跟单详情':'方案详情'" left-arrow @click-left="fLeft" />
			</van-sticky>
		</div>
		<div class="planDetail_body app_scroll">
			<p v-if="oPlanDetail.isCanCopy" class="planDetail_title">
				*奖金优化方案跟单后会与发单者优化的投注倍数有所差别
			</p>


<!--			方案详情-->
			<div class="planDetail_userInfo">
				<div class="planDetail_user">
					<div class="planDetail_user_left">
						<img class="planDetail_user_headPortrait"
							:src="oRoleDetail.head ? oRoleDetail.head : defaultAvatar" alt="" @click="toZhuye(oRoleDetail.roleId)">
						<div class="planDetail_user_info">
							<div class="planDetail_user_info_top">
								<div class="planDetail_user_info_top_l">
									<span class="planDetail_user_info_top_name">{{oRoleDetail.roleName}}</span>
									<div class="planDetail_user_info_top_red" v-if="oRoleDetail.evenRedCount">
										<img class="planDetail_user_info_top_red_img" src="~@/assets/icon/icon-001.png" alt="">
										<em>{{oRoleDetail.evenRedCount}}</em><span>连红</span>
									</div>
								</div>


							</div>
							<p class="planDetail_user_info_tip">
								<span class="planDetail_user_info_tip_a">粉丝</span>
								<span class="planDetail_user_info_tip_b" style="margin-right: 0.267rem">{{oRoleDetail.fansCount}}</span>
								<span class="planDetail_user_info_tip_a">近7天回报率</span>
								<span class="planDetail_user_info_tip_b">{{oRoleDetail.rate}}%</span>
							</p>
						</div>
					</div>
					<div class="planDetail_user_right" v-if="!oCPData.isZj">
						<div class="user_r" v-if="!oRoleDetail.focusState && oRoleDetail.roleId != oCPData.roleId" @click="focusOnFun">
							+ 关注
						</div>
						<div class="user_r user_r1" v-if="oRoleDetail.focusState && oRoleDetail.roleId != oCPData.roleId" @click="focusCancelFun">
							√ 关注
						</div>
					</div>
				</div>
				<div class="planDetail_record" v-if="oRoleDetail.evenRedList && oRoleDetail.evenRedList.length > 0">
					<template v-for="(item,index) in oRoleDetail.evenRedList" :key="index">
						<img v-if="index === 0 && item === 1" src="~@/assets/icon/icon-003.png" alt="">
						<img v-else-if="index === 0 && item === 0" src="~@/assets/icon/icon-005.png" alt="">
						<img v-else-if="item === 1" src="~@/assets/icon/icon-004.png" alt="">
						<img v-else-if="item === 0" src="~@/assets/icon/icon-006.png" alt="">
					</template>
				</div>
			</div>


			<div class="planDetail_planInfo">
				<div class="planDetail_planInfo_title">
					<div class="planDetail_planInfo_titl_box">
						<div class="planDetail_planInfo_title_border--blue" v-if="oPlanDetail.lotterySubTypeEnum === 'JCZQ'">
						</div>
						<div class="planDetail_planInfo_title_border--red" v-else-if="oPlanDetail.lotterySubTypeEnum === 'JCLQ'"></div>
						<div class="planDetail_planInfo_title_border--purple" v-else></div>
						<span class="planDetail_planInfo_title_a">{{oPlanDetail.plan ? oPlanDetail.plan.lotteryPlanType : ''}}</span>
						<span class="planDetail_planInfo_title_b">{{oPlanDetail.plan ? oPlanDetail.plan.lotteryPlanSubType : ''}}</span>
					</div>
					<div class="planDetail_planInfo_titl_box_r">

						<img src="~@/assets/images/new/new_029.png" alt="" class="img3" @click="jvbaoK">
						<img src="~@/assets/images/new/jc_023.png" alt="" class="img2">
						<div class="font" :style="oPlanDetail.yesNo ? 'color: #FF7671' : ''">{{oPlanDetail.agreeNum}}</div>

						<img src="~@/assets/images/new/jc_022.png" alt="" class="img1" v-if="oPlanDetail.yesNo" @click="zanFan(false)">

						<img src="~@/assets/images/new/ic_02.png" alt="" class="img1" v-else @click="zanFan(true)">
						<div class="tan_box" v-if="oCPData.isJb">
							<div class="tan">
								<img src="~@/assets/images/new/new_030.png" alt="" @click="jvbaoG">
								<div @click="jvbaoFun">举报</div>
							</div>
						</div>
					</div>


				</div>

				<div class="planDetail_planInfo_info">
					<div class="planDetail_planInfo_info_div">
						<div class="planDetail_planInfo_info_title">
							<div class="planDetail_planInfo_info_title_left">
								<div class="planDetail_planInfo_info_title_left_a" v-if="oPlanDetail.plan ? oPlanDetail.plan.prizeFee : false">
									<span class="planDetail_planInfo_info_title_left_lable">奖金</span>
									<span
										class="planDetail_planInfo_info_title_left_value">￥{{oPlanDetail.plan.prizeFee}}</span>
								</div>
								<div class="planDetail_planInfo_info_title_left_b">
									<span class="planDetail_planInfo_info_title_left_lable">投注</span>
									<span
										class="planDetail_planInfo_info_title_left_value">￥{{oPlanDetail.plan ? oPlanDetail.plan.totalFee : "--"}}</span>
								</div>
								<p class="planDetail_planInfo_info_title_left_time">
									截止时间：{{fnFormatData((oPlanDetail.plan ? oPlanDetail.plan.stopCopy : ''),"MM-dd hh:mm")}}</p>
							</div>
							<template v-if="oPlanDetail.plan">
								<template v-if="oPlanDetail.plan.prizeFee">
									<img v-if="oPlanDetail.plan.isWinning && (oPlanDetail.plan.planStatus =='20' || oPlanDetail.plan.planStatus =='25')" class="planDetail_planInfo_info_title_right" src="~@/assets/images/img_017.png" alt="">
									<img v-else-if="oPlanDetail.plan.isWinning && oPlanDetail.plan.planStatus =='30'" class="planDetail_planInfo_info_title_right" src="~@/assets/images/img_018.png" alt="">
									<img v-else-if="!oPlanDetail.plan.isWinning && oPlanDetail.plan.planStatus =='20'" class="planDetail_planInfo_info_title_right" src="~@/assets/images/img_019.png" alt="">
									<p v-else class="planDetail_planInfo_info_title_right--status">
										{{fnPlanDetailsStatusCode(oPlanDetail.plan.planStatus)}}
									</p>
								</template>

							</template>

						</div>
						<div v-if="oPlanDetail.plan ? oPlanDetail.plan.bonusOptimizedSign :'' " class="planDetail_planInfo_way--optimize">奖金优化</div>
						<div v-else class="planDetail_planInfo_way">
							<span v-for="(item,index) in oPlanDetail.mixTypeList" :key="index">{{item}}</span>
						</div>

						<div class="planDetail_planInfo_bet" v-if="oPlanDetail.plan">
							<div>
								<span>{{(oPlanDetail.plan.heat>= 0) ? oPlanDetail.plan.heat : "--"}}</span>
								<span>热度</span>
							</div>
							<div>
								<span>
									<template
										v-if="oPlanDetail.plan.sourceType === 0 && oPlanDetail.plan.canCopy === 1 && oPlanDetail.plan.profitProtectRate">
										{{ oPlanDetail.plan.profitProtectRate }}
									</template>
									<template v-else>
										--
									</template>
								</span>
								<span>保障赔率</span>
							</div>
							<div>
								<span>{{!oPlanDetail.plan.bonusOptimizedSign && oPlanDetail.plan.multiple ? oPlanDetail.plan.multiple : "--"}}</span>
								<span>投注倍数</span>
							</div>
						</div>
					</div>

					<div class="planDetail_planInfo_describe" v-if="oPlanDetail.plan ? oPlanDetail.plan.planName : false">
						<p>
							{{oPlanDetail.plan.planName}}
						</p>
					</div>
				</div>
			</div>



			<template v-if="oPlanDetail.plan">
				<template v-if="!oPlanDetail.plan.isCanCopy">
					<div class="planDetail_way">
						<div class="planDetail_way_left">
							<div class="planDetail_way_left_border--blue" v-if="oPlanDetail.plan.lotterySubTypeEnum === 'JCZQ'">
							</div>
							<div class="planDetail_way_left_border--red" v-else-if="oPlanDetail.plan.lotterySubTypeEnum === 'JCLQ'">
							</div>
							<div class="planDetail_way_left_border--purple" v-else></div>
							<span class="planDetail_way_left_a">投注方式</span>
							<span class="planDetail_way_left_b--yellow" v-if="oPlanDetail.plan.sourceType === 0 && oPlanDetail.plan.canCopy == 1">
							发单
						</span>
							<span class="planDetail_way_left_c" v-else-if="oPlanDetail.plan.sourceType === 0 && oPlanDetail.plan.canCopy == 0">
							自购
						</span>
							<span class="planDetail_way_left_b--red" v-else>
							跟单
						</span>
						</div>
					</div>
				</template>
			</template>


			<div class="planDetail_betInfo">
				<div class="planDetail_betInfo_title">
					<div class="planDetail_betInfo_title_left">
						<div class="planDetail_betInfo_title_left_border--blue"
							v-if="(oPlanDetail.plan ? oPlanDetail.plan.lotterySubTypeEnum : '') === 'JCZQ'"></div>
						<div class="planDetail_betInfo_title_left_border--red"
							v-else-if="(oPlanDetail.plan ? oPlanDetail.plan.lotterySubTypeEnum : '') === 'JCLQ'"></div>
						<div class="planDetail_betInfo_title_left_border--purple" v-else></div>
						<span class="planDetail_betInfo_title_left_a">方案信息</span>
					</div>
<!--					<div class="planDetail_betInfo_title_right">-->
<!--						<span class="planDetail_betInfo_title_right_id">{{oPlanDetail.planNo}}</span>-->
<!--						<span class="planDetail_betInfo_title_right_copy" @click="fCopyText()">复制</span>-->
<!--					</div>-->
				</div>

				<div class="planDetail_betInfo_body" v-if="oPlanDetail.plan">
					<template v-if="!oPlanDetail.plan.isLottery">
						<img class="planDetail_betInfo_body_mask" src="~@/assets/images/img_010.png" alt="">
					</template>
					<template v-else-if="oPlanDetail.plan.lotterySubTypeEnum === 'JCLQ'">
						<ul class="planDetail_betInfo_body_option">
							<li>
								<div>场次</div>
								<div>客队/主队</div>
								<div>玩法</div>
								<div>选项</div>
								<div>赛果</div>
								<div>胆</div>
							</li>
							<!-- 判断isAllMatchStart是否为true，如果为true表示所有比赛已开赛，false表示部分比赛已开赛 -->
							<li v-for="(item,index) in oPlanDetail.planItems" :key="index">
								<div>
									<p>{{item.weekStr}}</p>
									<p>{{item.gameNo}}</p>
								</div>
								<div>
									<p>{{item.guestTeamShortName}}</p>
									<template
										v-if="item.state === 'DELAY' || item.state === 'CANCEL' || item.state === 'OUT'">
										<p>VS</p>
									</template>
									<template v-else>
										<p>{{item.prized && item.fullHomeScore && item.fullGuestScore ? item.fullGuestScore +":"+ item.fullHomeScore : 'VS'}}
										</p>
									</template>
									<p>{{item.homeTeamShortName}}</p>
								</div>
								<div>
									<p class="breakSpaces">{{item.playTypeName}}</p>
									<template v-if="item.handicapList && item.handicapList.length===1">
										<p>{{item.handicapList[0]}}</p>
									</template>
									<template v-else-if="item.handicapList && item.handicapList.length>1">
										<select class="select_clearCSS">
											<option v-for="(itemA,indexA) in item.handicapList" :key="indexA"
												:value="itemA" :disabled="indexA!==0">{{itemA}}</option>
										</select>
									</template>
								</div>
								<div class="planDetail_betInfo_body_option_options">
									<template v-for="(itemA,indexA) in item.planItemDetails" :key="indexA">
										<p
											:class="{'planDetail_betInfo_body_option_options_iswin':fResult(item, itemA, oPlanDetail.plan.lotteryTypeEnum)}">
											{{itemA.lotteryResultMsg}}
										</p>
										<!-- <p :class="{'planDetail_betInfo_body_option_options_iswin':fResult(item,itemA,'竞彩篮球')}">({{itemA.award}})</p> -->
										<template v-if="itemA.oddOnlyList && itemA.oddOnlyList.length===1">
											<p
												:class="{'planDetail_betInfo_body_option_options_iswin':fResult(item, itemA, oPlanDetail.plan.lotteryTypeEnum)}">
												{{itemA.oddOnlyList[0]}}
											</p>
										</template>
										<template v-else-if="itemA.oddOnlyList && itemA.oddOnlyList.length>1">
											<select class="select_clearCSS">
												<option v-for="(itemB,indexB) in itemA.oddOnlyList" :key="indexB"
													:value="itemB" :disabled="indexB!==0">{{itemB}}</option>
											</select>
										</template>
									</template>
								</div>
								<div>
									<p>{{item.result}}</p>
								</div>
								<div>
									<p :class="{'c2315067':item.isDan}">{{item.isDan?'是':'否'}}</p>
								</div>
							</li>
						</ul>
					</template>
					<template v-else>
						<ul class="planDetail_betInfo_body_option">
							<li>
								<div>场次</div>
								<div>主队/客队</div>
								<div>玩法</div>
								<div>选项</div>
								<div>赛果</div>
								<div class="planDetail_dan" v-if="oPlanDetail.plan.lotterySubTypeEnum !== 'BJDC'">胆</div>
							</li>
							<li v-for="(item,index) in oPlanDetail.planItems" :key="index">
								<div>
									<p>{{item.weekStr}}</p>
									<p>{{item.gameNo}}</p>
								</div>
								<div>
									<p>{{item.homeTeamShortName}}</p>
									<template
										v-if="item.state === 'DELAY' || item.state === 'CANCEL' || item.state === 'OUT'">
										<p>VS</p>
									</template>
									<template v-else>
										<p>{{item.prized && item.fullHomeScore >= 0 && item.fullGuestScore >= 0 ? item.fullHomeScore +":"+ item.fullGuestScore : 'VS'}}</p>
									</template>
									<p>{{item.guestTeamShortName}}</p>
								</div>
								<div>
									<template v-if="item.lotterySubTypeEnum == 'JCZQ_SPF'">
										<p>胜平负</p>
									</template>
									<!-- <template v-else-if="item.lotterySubTypeEnum == 'JCZQ_RQSPF'">
											<p>让球({{item.rqspfPlate >=1 ? '+'+item.rqspfPlate : item.rqspfPlate}})</p>
										</template> -->
									<template v-else>
										<p class="breakSpaces">{{item.playTypeName}}</p>
										<template v-if="item.handicapList && item.handicapList.length===1">
											<p>{{item.handicapList[0]}}</p>
										</template>
										<template v-else-if="item.handicapList && item.handicapList.length>1">
											<select class="select_clearCSS">
												<option v-for="(itemA,indexA) in item.handicapList" :key="indexA"
													:value="itemA" :disabled="indexA!==0">{{itemA}}</option>
											</select>
										</template>
									</template>
								</div>
								<div class="planDetail_betInfo_body_option_options">
									<template v-for="(itemA,indexA) in item.planItemDetails" :key="indexA">
										<p
											:class="{'planDetail_betInfo_body_option_options_iswin':fResult(item, itemA, oPlanDetail.plan.lotteryTypeEnum)}">
											{{itemA.lotteryResultMsg}}
										</p>
										<template v-if="itemA.oddOnlyList && itemA.oddOnlyList.length===1">
											<p
												:class="{'planDetail_betInfo_body_option_options_iswin':fResult(item, itemA, oPlanDetail.plan.lotteryTypeEnum)}">
												{{itemA.oddOnlyList[0]}}
											</p>
										</template>
										<template v-else-if="itemA.oddOnlyList && itemA.oddOnlyList.length>1">
											<select class="select_clearCSS">
												<option v-for="(itemB,indexB) in itemA.oddOnlyList" :key="indexB"
													:value="itemB" :disabled="indexB!==0">{{itemB}}</option>
											</select>
										</template>
									</template>
								</div>
								<div>
									<p>{{item.result}}</p>
								</div>
								<div class="planDetail_dan" v-if="oPlanDetail.plan.lotterySubTypeEnum !== 'BJDC'">
									<p :class="{'c2315067':item.isDan}">{{item.isDan?'是':'否'}}</p>
								</div>
							</li>
						</ul>
					</template>

				</div>

				<div class="planDetail_betInfo_tip">
					<em>*</em>
					<p v-if="(oPlanDetail.plan ? oPlanDetail.plan.lotterySubTypeEnum : '')">页面显示盘口及赔率仅供参考，奖金计算请以实际出票时的盘口及赔率为准</p>
					<p v-else>页面显示盘口及赔率仅供参考，奖金计算请以开奖赔率为准</p>
				</div>
			</div>

			<div v-if="(oPlanDetail.plan ? oPlanDetail.plan.lotterySubTypeEnum : '') !== 'BJDC'" class="planDetail_otherInfo">
				<van-tabs class="planDetail_van-tabs" v-model:active="nActive" color="#FF7671" background="#FFFFFF"
					line-width="0.48rem" line-height="0.08rem" swipeable title-active-color="#383838"
					title-inactive-color="#383838">
					<van-tab title="跟单信息">

						<!--						<div v-if="oPlanDetail.records.length<=0" class="app_none_empty" style="height: 7.92rem"></div>-->
						<van-empty v-if="(oPlanDetail.records ? oPlanDetail.records.length : '')<=0" class="app_none_empty" :image="require('@/assets/images/img_021.png')" description="暂无跟单信息" />
						<template v-else>
							<template  v-if="oPlanDetail.records">
								<div id="HTableB">
									<ul class="HTableB_ul">
										<li>
											<span v-for="(item, index) in oCPData.gendanData" :key="index">{{item}}</span>
										</li>
										<li v-for="(item, index) in oPlanDetail.records" :key="index">
											<span v-for="(itemA,indexA) in oCPData.gendanData" :key="indexA">
												<template v-if='indexA === 0'>
													<div class="HTableB_bet_sessions" >
														<p class="HTableB_bet_sessions_option">{{item.roleName}}</p>
													</div>
												</template>
												<template v-if='indexA === 1'>
													<div class="HTableB_bet_sessions" >
														<p class="HTableB_bet_sessions_option">{{item.totalFee}}</p>
													</div>
												</template>
												<template v-if='indexA === 2'>
													<div class="HTableB_bet_sessions" >
														<p class="HTableB_bet_sessions_option">{{item.prizeFee}}</p>
													</div>
												</template>

											</span>
										</li>
									</ul>
								</div>
							</template>





							<!--							<img :src=item alt=""  v-for="(item,index) in oPlanDetail.records"  :key="index" style="width: 355px;margin-left: 6px;" @click="getImg(oPlanDetail.records,index)">-->
						</template>


					</van-tab>
					<van-tab title="奖金优化">
						<img v-if="(oPlanDetail.plan ? oPlanDetail.plan.bonusOptimizedSign && !oPlanDetail.plan.isAllMatchStart && !oPlanDetail.plan.isLottery: '')  " class="planDetail_otherInfo_mask"
							src="~@/assets/images/img_010.png" alt="">
						<van-empty v-else-if="aOptimized.length<=0" class="app_none_empty"
							:image="require('@/assets/images/img_021.png')" description="暂无奖金优化" />
						<template v-else>
							<h-table-c :isDisabled="true" :aContent="aOptimized"
								:aLotteryType="(oPlanDetail.plan ? oPlanDetail.plan.lotterySubTypeEnum : '')"></h-table-c>
						</template>
					</van-tab>

				</van-tabs>
			</div>


			<div v-else class="planDetail_betInfo">
				<div class="planDetail_betInfo_title">
					<div class="planDetail_betInfo_title_left">
						<div class="planDetail_betInfo_title_left_border--blue"
							v-if="oPlanDetail.lotterySubTypeEnum === 'JCZQ'"></div>
						<div class="planDetail_betInfo_title_left_border--red"
							v-else-if="oPlanDetail.lotterySubTypeEnum === 'JCLQ'"></div>
						<div class="planDetail_betInfo_title_left_border--purple" v-else></div>
						<span class="planDetail_betInfo_title_left_a">出票信息</span>
					</div>
				</div>
				<div class="planDetail_betInfo_body--other">
					<img v-if="!oPlanDetail.isLottery" class="planDetail_otherInfo_mask"
						src="~@/assets/images/img_010.png" alt="">
					<van-empty v-else-if="aTicketGroup.length<=0" class="app_none_empty"
						:image="require('@/assets/images/img_021.png')" description="暂无小票信息" />
					<template v-else>
						<h-table-b :aTitle="oCPData.oNCData.titleB" :aContent="aTicketGroup"
							:bLotteryTypeEnum="oPlanDetail.lotterySubTypeEnum === 'BJDC'">
						</h-table-b>
					</template>
				</div>
			</div>

			<!--		评论开始-->
			<div class="pinlun">
				<div class="title">
					<div class="shu"></div>
					<div class="font">全部评论 {{oCPData.msgData.length}}</div>
				</div>
				<div class="zwpl" v-if="oCPData.msgData.length == 0">
					还没有人留意，你来说两句
				</div>
				<div class="pl_box" v-else>
					<div class="pl_item" v-for="(item,index) in oCPData.msgData" :key="index">
						<div class="pl_item_l">
							<img :src="item.commentPic" alt="" v-if="item.commentPic" @click="toZhuye(item.commentRoleId)">
							<img src="~@/assets/images/new/new_019.png" alt="" v-else @click="toZhuye(item.commentRoleId)">
						</div>
						<div class="pl_item_r">
							<div class="item_r_top">
								<div class="r_top_name">
									{{item.commentRoleName}}
								</div>
								<div class="r_top_img">
									<img src="~@/assets/images/new/ic_017.png" alt="" v-if="item.agreeSign" @click="dianz(false,item)">
									<img src="~@/assets/images/new/ic_016.png" alt="" v-else  @click="dianz(true,item)">
									<div>{{item.commentAgreeNum}}</div>
								</div>

							</div>
							<div class="item_zhu" @click="toPlDetail(item)">
								{{item.commentText}}
							</div>
							<div class="item_gd" v-if="item.replyTotalNum && item.replyTotalNum > 0" @click="toPlDetail(item)">
								查看全部 {{item.replyTotalNum}} 条评论 >
							</div>
							<div class="item_bot">
								<div class="item_bot_l">
									{{item.commentTime}}
								</div>
								<div class="item_bot_r" v-if="item.commentRoleId == oCPData.roleId" @click="shanp(item)">
									删除
								</div>
								<div class="item_bot_r" @click="jvbaoPl" v-else>
									举报
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--		评论结束-->
		</div>










		<div class="planDetail_bottom" v-if="oPlanDetail.isCanCopy">
			<van-checkbox class="planDetail_checkbox_text" v-model="oCalculate.isAgree" checked-color="#FF7671"
				label-disabled>
				<p>我已阅读并同意<span class="app_agreement" @click="fnGoGeneralTxt('documentary')">《跟单协议》</span></p>
				<p>请注意盘口变化！跟单有风险，购买需谨慎！</p>
			</van-checkbox>

			<div class="planDetail_form">
				<van-stepper v-model="oCalculate.multiple" integer :min="oCalculate.minBetMultiple" max="100000"
					input-width="1.6533rem" />
				<span class="planDetail_form_money"><em>{{calculate_totalFee}}</em>元</span>
				<van-button class="planDetail_form_btn" color="linear-gradient(180deg, #FF7671 0%, #E2335D 100%)" round
					@click="fBeginPay(false)">
					彩店出票
				</van-button>
			</div>
		</div>
		<div class="planDetail_bottom_a" v-else-if="oPlanDetail.planStatus == 1">
			<van-button class="planDetail_bottom_a_btn" color="linear-gradient(180deg, #FF7671 0%, #E2335D 100%)" round
				@click="fBeginPay(true)">
				去付款
			</van-button>
		</div>
		<div class="planDetail_bottom_a" v-else-if="oPlanDetail.issue && oPlanDetail.lotterySubTypeEnum !== 'BJDC'">
			<van-button class="planDetail_bottom_a_btn" color="linear-gradient(180deg, #FF7671 0%, #E2335D 100%)" round
				@click="fGoRelease()">
				发布跟单
			</van-button>
		</div>
		<h-dialog-c title="提示" dialogKey="dialogCA" :show="oCPData.oYCData.dialogCA.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCA.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCA.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="planDetail_tips">
				您已绑定{{oPayPlanDetail.shopName}}，支付成功后在该店出票。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCB" :show="oCPData.oYCData.dialogCB.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCB.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCB.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="planDetail_tips">
				当前投注盘口变化,是否继续完成支付?
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCC" :show="oCPData.oYCData.dialogCC.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCC.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCC.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="planDetail_tips">
				您有跟单方案未支付佣金，请先支付完佣金后再投注。
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCD" :show="oCPData.oYCData.dialogCD.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCD.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCD.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="planDetail_tips planDetail_dialogCD">
				您确定要放弃支付吗？
			</div>
		</h-dialog-c>
		<h-dialog-c title="提示" dialogKey="dialogCE" :show="oCPData.oYCData.dialogCE.showDialog"
			:showCancelButton="oCPData.oYCData.dialogCD.showCancelButton"
			:confirmButtonText="oCPData.oYCData.dialogCD.confirmButtonText" @fBeforeClose="fbutsurFun">
			<div class="planDetail_tips planDetail_dialogCD">
				您确定要求店主上传出票小票照片吗？
			</div>
		</h-dialog-c>
		<h-actionSheet-a :title="oPayRelated.title" :show="oPayRelated.showDialog" :payDetail="oPayRelated.payDetail"
			:oButton="oPayRelated.oButton" sRechargeRedirect="goback" @fBeforeClose="fBeforeCloseActionSheetA" @fSubmit="fSubmitActionSheetA">
		</h-actionSheet-a>
		<Drag
			v-if="oCPData.oYCData.oDrag && (Number(oCPData.oYCData.oDrag.countUsable) > 0 || oCPData.oYCData.oDrag.countUsable === '99+')"
			:ball="oCPData.oYCData.oDrag" @click="fGoRedPack"></Drag>
		<div class="down-box" v-if="isDown">
			<div >长按图片保存</div>
		</div>
		<template v-if="oPlanDetail.plan" >
			<div class="pinlum_but" :style="oPlanDetail.plan.planStatus != 15 || oPlanDetail.plan.sourceType === 1 || oPlanDetail.plan.sourceType === 0 && !oPlanDetail.plan.isCanCopy ? 'justify-content: center;' : 'justify-content: space-between;'">
				<img src="~@/assets/images/new/new_020.png" alt="" @click="plTan">
				<template v-if="oPlanDetail.plan.sourceType === 0 && oPlanDetail.plan.isCanCopy">
					<div class="bei">
						<span style="font-size: 0.427rem">1</span>
						<span style="color: #383838;margin: 0 0.213rem 0 0.107rem">倍</span>
						<span style="margin-right: 0.107rem;font-size: 0.427rem">{{oPlanDetail.plan.singleFee}}</span>
						<span style="color: #383838;">元</span>
					</div>
					<div class="but" @click="toHy">一键到店</div>
				</template>

			</div>
		</template>

		<van-popup v-model:show="oCPData.show" position="bottom" class="tan_put" :style="{ height: '1.6rem' }"  :overlay="oCPData.overlay">
			<div class="tan_put_box">
				<input type="text" placeholder="请输入评论内容" v-model="oCPData.inputData" @input="showBut">
				<div :style="oCPData.inputShow ? 'background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);' : ''" @click="pinglunFun">发布</div>
			</div>
		</van-popup>
<!--		举报弹出层-->
		<van-popup v-model:show="oCPData.jbShow" position="bottom" class="tan_put"   closeable :overlay="oCPData.jbBverlay">
			<div class="jb_box">
				<div class="jb_item" v-for="(item,index) in oCPData.jbData" :key="index" @click="jvbao(item)">
					{{item.informTypeName}}
				</div>
			</div>
		</van-popup>

		<h-dialog-c title="联系店主" dialogKey="dialogC" :show="dialogC.showDialog" :showCancelButton="dialogC.showCancelButton" :confirmButtonText="dialogC.confirmButtonText" @fBeforeClose="fBeforeClose">
			<div class="footBall_tips" style="text-align: center">
				店主QQ：709674174
			</div>
		</h-dialog-c>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		inject,
		onActivated,
		computed
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		useRoute,
		useRouter,
		// onBeforeRouteLeave
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	import {
		fnIsHasHistory,
		fnGoGeneralTxt
	} from "@/utils/router.js";
	import {
		fnFormatData,
		fnMixTypes,
		fnPlanSuccess,
		fnPlanDetailsStatusCode,
		fnCopyInnerText,
		uaFun
	} from "@/utils/public.js";
	import tipsContent from '@/utils/tipsContent';
	import {
		apiGetPlanDetailNew,
		planTicketAppeal,
		listComment,
		saveComment,
		saveAgree,
		listAccuseInfo,
		accuseReply,
		delReply
	} from '@/api/plan.js';
	import {
		apiGetPlatformPlanDetail
	} from '@/api/other';
	import {
		apiPlanLotteryShop,
		apiOrderPayPlateCheck,
		apiOrderPay
	} from '@/api/allplay.js';
	import {
		apiPlanRedpackNum
	} from '@/api/redPage.js';
	import {
		focusOn,
		focusCancel
	} from '@/api/home.js';
	import { ImagePreview }  from 'vant';
	import Drag from "@/components/Public/drag.vue";
	import HTableB from "@/components/HTable/tableB.vue";
	import HTableC from "@/components/HTable/tableC.vue";
	import HActionSheetA from "@/components/HActionSheet/HActionSheetA";
	import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import defaultAvatar from "@/assets/images/img_032.png";
	export default {
		name: "PlanDetail",
		components: {
			HTableB,
			HTableC,
			HActionSheetA,
			HDialogC,
			Drag
		},
		setup() {
			const isApp = uaFun()
			const isDown = ref(false);
			const imaData = ref([]);
			const indexData = ref(0);
			// tab标签页下标
			const nActive = ref(0);
			const route = useRoute();
			const router = useRouter();
			const fGlobalReload = inject("fGlobalReload");
			const route_query = route.query;
			const store = useStore();

			const UserInfo = store.state.userInfo.userInfo;

			const oCPData = reactive({
				isZj:false,
				isJb:false,
				jbShow:false,
				jbData:[],
				jbBverlay:false,
				oNCData: {
					titleA: ['跟单者', '跟单金额（元）', '中奖金额（元）'],
					titleB: ['场次', '倍数', '过关方式', '张数', '中奖状态'],
				},
				oYCData: {
					oDrag: {
						countUsable: 0
					},
					dialogCA: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCB: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					},
					dialogCC: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					},
					dialogCD: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCE: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					}
				},
				msgData:[],
				msgTotal:0,
				show:false,
				inputData:'',
				overlay:false,
				inputShow:false,
				gendanData:["跟单者","跟单金额(元)","中奖金额(元)"],
				roleId:UserInfo.roleId
			});
			// 获取消息列表
			const msgFun = () =>{
				listComment({
					"current": 1,
					"entityId": route_query.id,
					"jumpType": "NORMAL",
					"roleId": UserInfo.roleId,
					"size": 20,
					"type": "PLAN",
					"userId": UserInfo.userId
				}).then(res=>{
					console.log(res);
					oCPData.msgData = res.result.records
					oCPData.msgTotal = res.result.total
				})
			};
			// 订单详情
			const oRoleDetail = ref({});
			const oPlanDetail = ref({
				mixTypeList: []
			});
			// 奖金优化信息
			const aOptimized = ref([]);
			// 小票信息
			const aTicketGroup = ref([]);
			const imgData = ref([]);
			// 支付相关信息
			const oPayRelated = reactive({
				payDetail: {
					planId: null, // 方案ID
					isShowPrePrizeAmount: false, // 是否展示理论最高金额
					isPlateChange: false, // 方案盘口是否发生改变
					availableAmount: 0, // 店内余额
					totalFee: 0, // 方案金额
					isEnough: false, // 是否可以支付
					lotteryType: "JCZQ",
				},
				oButton: {
					isShow: true,
					text: "确定"
				},
				showDialog: false, //是否展示弹框
				title: "彩店出票"
			});
			// 支付的方案信息
			const oPayPlanDetail = reactive({
				shopName: "",
				shopId: "",
				IsHasPlanId: "",
			});
			// 方案计算信息
			const oCalculate = reactive({
				isAgree: false, // 是否同意跟单协议
				multiple: 1, // 下注倍数
				minBetMultiple: 1, // 最低下注倍数
				minTotalFee: null, // 最低下注金额
				prePrizeAmount: 0, // 理论最高奖金
				prePrizeAmountMin: 0, // 理论最低奖金
				singleFee: 0, // 方案投注金额
			})
			// 方案投注金额
			const calculate_totalFee = computed(() => {
				return oCalculate.singleFee * 100 * oCalculate.multiple / 100
			});


			// 可用红包数量
			const fGetPlanRedpackNum = () => {
				apiPlanRedpackNum({
					id: route_query.id
				}).then(res => {
					let tData = res.result;
					if (tData > 99) {
						tData = "99+"
					}
					oCPData.oYCData.oDrag.countUsable = tData ? tData : 0;
				}).catch(() => {
					oCPData.oYCData.oDrag.countUsable = 0;
				})
			};
			// 获取订单详情
			const fGetPlanDetail = () => {
				Toast({
					type: "loading"
				});
				let tPromise = null;
				if (route_query.copyPlanId) {
					tPromise = apiGetPlatformPlanDetail({
						planId: route_query.copyPlanId,
						currentRoleId: UserInfo.roleId,
					})
				} else {
					tPromise = apiGetPlanDetailNew({
						planId: route_query.id,
						roleId: UserInfo.roleId,
					})
				}

				tPromise.then(res => {
					// 获取跟单详情
					let tODetail = res.result && res.result.roleDetailResponse ? res.result.roleDetailResponse : {};
					let tOplanDetail = res.result && res.result.planDetailResponse ? res.result.planDetailResponse : {};
					tOplanDetail.mixTypeList = tOplanDetail.plan ? tOplanDetail.plan.mixTypes.split("^") : '';
					imgData.value = tOplanDetail.optimizedInfos ? tOplanDetail.optimizedInfos : [];


					console.log(fnPlanSuccess(tOplanDetail.planStatus))
					if (tOplanDetail.plan.isCanCopy && tOplanDetail.plan.sourceType == 0 && tOplanDetail.plan.canCopy == 1 && fnPlanSuccess(tOplanDetail.plan.planStatus) && tOplanDetail.plan.lotterySubTypeEnum !== 'BJDC') {
						fGetPlanRedpackNum();
					}
					if (tOplanDetail.optimizedInfos) {

						tOplanDetail.optimizedInfos.forEach(item => {
							item.isOpen = false;
							item.isDisable = false;
						})
						aOptimized.value = tOplanDetail.optimizedInfos;
					}
					console.log(aOptimized)

					if (tOplanDetail.ticketGroupList) {
						let tArrB = []
						for (let i = 0, j = tOplanDetail.ticketGroupList.length; i < j; i++) {
							let lObj = {};
							lObj['betInfoList'] = tOplanDetail.ticketGroupList[i]['betInfoList'];
							lObj['multiple'] = tOplanDetail.ticketGroupList[i]['multiple'];
							lObj['mixTypes'] = tOplanDetail.ticketGroupList[i]['mixTypes'];
							lObj['ticketTotalQuantity'] = tOplanDetail.ticketGroupList[i][
								'ticketTotalQuantity'
							];
							lObj['winState'] = tOplanDetail.ticketGroupList[i]['winState'];
							tArrB.push(lObj);
						}
						aTicketGroup.value = tArrB;
					}
					// 获得单注金额
					oCalculate.singleFee = tOplanDetail.singleFee;
					// 获取最小投注倍数
					oCalculate.minBetMultiple = tOplanDetail.minBetMultiple;
					// 获取当前倍数
					oCalculate.multiple = tOplanDetail.minBetMultiple;
					// 最小投注金额
					oCalculate.minTotalFee = tOplanDetail.minTotalFee;

					oRoleDetail.value = tODetail;
					oPlanDetail.value = tOplanDetail;
					if(tODetail.userId == UserInfo.userId){
						oCPData.isZj = true
					}{
						oCPData.isZj = false
					}
				})
			};
			const getImg = (images,index) => {
				isDown.value = true;
				imaData.value = images;
				indexData.value = index;
				ImagePreview({
					images: images,
					showIndex: true,
					loop: false,
					closeable:true,
					showIndicators: index,
					onClose(){
						isDown.value = false
					},
					onChange(e){
						indexData.value = e
					}
				});
			};


			// 开始支付
			const fBeginPay = (IsHasPlanId) => {
				oPayPlanDetail.IsHasPlanId = IsHasPlanId;
				if (oPlanDetail.value.isCanCopy && !oCalculate.isAgree) {
					return Toast({
						message: tipsContent.disagreeRead + "《跟单协议》哦",
						position: 'bottom'
					});
				}
				apiPlanLotteryShop({
					roleId: UserInfo.roleId,
					userId: UserInfo.userId,
					lotterySubTypeEnum: oPlanDetail.value.lotterySubTypeEnum
				}).then(res => {
					let tData = res.result ? res.result : {};
					let tShopName = "";
					if (tData.canBet) {
						for (let i = 0, j = tData.lotteryShopList.length; i < j; i++) {
							if (i === 0) {
								tShopName = "" + tData.lotteryShopList[i].shopName
							} else {
								tShopName = tShopName + "/" + tData.lotteryShopList[i].shopName
							}
						}
						oPayPlanDetail.shopName = tShopName;
						// 默认取第一个彩票店ID，暂时不考虑多个彩票店
						oPayPlanDetail.shopId = tData.lotteryShopList[0].shopId;
						oCPData.oYCData.dialogCA.showDialog = true;
					}
				})
			};

			const fbutFun = () => {
				oCPData.oYCData.dialogCE.showDialog = true;
			};
			const fbutsurFun = (action) => {
				if(action == 'cancel'){
					oCPData.oYCData.dialogCE.showDialog = false;

				}else {
					planTicketAppeal({
						appealSource:'USER',
						planId:route_query.id,
						roleId:UserInfo.roleId,
						userId:UserInfo.userId
					}).then(res=>{
						if(res.success){
							Toast({
								message: "提交成功",
								position: 'bottom'
							});
						}
						oCPData.oYCData.dialogCE.showDialog = false;
					})
				}

			};

			// 弹框关闭之前
			const fBeforeClose = (action, oDialog, ) => {
				console.log(action, oDialog);
				if(action == 'cancel'){
					dialogC.showDialog = false

				}else {
					let tData = fnCopyInnerText("709674174");
					if (tData) {
						Toast({
							message: '复制成功',
							position: 'bottom'
						});
						dialogC.showDialog = false
					} else {
						Toast({
							message: '复制成功',
							position: 'bottom'
						});
						dialogC.showDialog = false
					}
				}


			};
			/* fBeforeCloseActionSheetA ActionSheetA关闭之前
			 * action 操作的按钮（confirm：确定；cancel：取消；overlay：点击遮罩层）
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fBeforeCloseActionSheetA = (action, fn) => {
				if (action === 'confirm') {
					oPayRelated.showDialog = false;
					return fn(false)
				} else if (action === 'cancel') {
					oCPData.oYCData.dialogCD.showDialog = true;
					return fn(true)
				}
			};
			/* fSubmitActionSheetA 支付的动作面板中的支付按钮
			 * fn 回调函数（true:阻止关闭，false可以关闭）
			 */
			const fSubmitActionSheetA = async (fn) => {
				// 该方案是否可支付
				if (!oPayRelated.payDetail.isEnough) return;

				Toast({
					type: "loading"
				});
				let tOrderPayPlateCheckResult = await apiOrderPayPlateCheck({
					id: oPayRelated.payDetail.planId
				});
				if (tOrderPayPlateCheckResult.result || oPayRelated.payDetail.isPlateChange) {
					return oCPData.oYCData.dialogCB.showDialog = true;
				}

				fGoPay(fn);
			};
			// 开始支付
			const fGoPay = (fn) => {
				Toast({
					type: "loading"
				});
				// 方案支付
				apiOrderPay({
					balance: oPayRelated.payDetail.totalFee,
					otherFee: 0,
					payType: 1,
					planId: oPayRelated.payDetail.planId,
					roleId: UserInfo.roleId,
					userId: UserInfo.userId
				}).then(res => {
					oCPData.oYCData.dialogCB.showDialog = false;
					fn(false);
					let tData = res.result;
					// 是否支付成功
					if (tData["payResult"]) {
						// 成功
						Toast({
							message: tData.message ? tData.message : "支付成功",
							position: 'bottom'
						});
						// 跳转到方案详情
						router.replace({
							name: 'Redirect',
							params: {
								path: "planDetail"
							},
							query: {
								id: oPayRelated.payDetail.planId
							}
						});
					} else if (tData["orderUnPayReasonEnum"] === "HAVE_UN_PAY_FEE") {
						// 失败:需要支付佣金--打开跳转到佣金弹框
						oCPData.oYCData.dialogCC.showDialog = true;
					} else {
						Toast({
							message: tData.message ? tData.message : "支付失败",
							position: 'bottom'
						});
						// 跳转到方案详情
						router.replace({
							name: 'Redirect',
							params: {
								path: "planDetail"
							},
							query: {
								id: oPayRelated.payDetail.planId
							}
						});
					}
				})
			};

			// 发布跟单页面
			const fGoRelease = () => {
				router.push({
					path: '/release',
					query: {
						planId: route_query.id
					}
				})
			};


			// 跳转红包列表页面
			const fGoRedPack = () => {
				let tQuery = {};
				if (route_query.id) {
					tQuery.shopPlanId = route_query.id
				} else if (route_query.copyPlanId) {
					tQuery.planId = route_query.copyPlanId
				} else {
					return
				}
				router.push({
					path: '/redPack',
					query: tQuery
				})
			};
			/*
			 * fLeft van-nav-bar 左侧点击事件
			 */
			const fLeft = () => {
				fnIsHasHistory("/plan");
			};
			// 赛果展示
			const fFilterLotterySubTypeEnum = (Item, Type) => {
				if (Item.state === 'DELAY' || Item.state === 'CANCEL' || Item.state === 'OUT') return Item.message;
				if (!Item.prized) return;
				if (Type == 'JCZQ') {
					if (Item.lotterySubTypeEnum == 'JCZQ_SPF') {
						return Item.spfPrizedResult
					} else if (Item.lotterySubTypeEnum == 'JCZQ_RQSPF') {
						return Item.rqspfPrizedResult
					} else if (Item.lotterySubTypeEnum == 'JCZQ_BF') {
						return Item.bfPrizedResult
					} else if (Item.lotterySubTypeEnum == 'JCZQ_BQC') {
						return Item.bqcspfPrizedResult
					} else if (Item.lotterySubTypeEnum == 'JCZQ_JQS') {
						return Item.jqsPrizedResult
					} else {
						return
					}
				} else if (Type == 'BJDC') {
					if (Item.lotterySubTypeEnum == 'BJDC_SFGG') {
						return Item.sfggResult
					} else if (Item.lotterySubTypeEnum == 'BJDC_SPF') {
						return Item.spfPrizedResult
					} else if (Item.lotterySubTypeEnum == 'BJDC_JQS') {
						return Item.jqsPrizedResult
					} else if (Item.lotterySubTypeEnum == 'BJDC_BQC') {
						return Item.bqcspfPrizedResult
					} else if (Item.lotterySubTypeEnum == 'BJDC_BF') {
						return Item.bfPrizedResult
					} else {
						return
					}
				} else if (Type == 'JCLQ') {
					if (Item.lotterySubTypeEnum == 'JCLQ_SF') {
						return Item.sfResult
					} else if (Item.lotterySubTypeEnum == 'JCLQ_RFSF') {
						return Item.rfsfResult
					} else if (Item.lotterySubTypeEnum == 'JCLQ_DXF') {
						return Item.dxfResult
					} else if (Item.lotterySubTypeEnum == 'JCLQ_SFC') {
						return Item.sfcResult
					} else {
						return
					}
				} else {
					return
				}
			};

			// 选择赛果是否中奖，中奖则为红色
			const fResult = (item, itemA, type) => {
				// JCZQ_HT, JCZQ_SPF,JCZQ_RQSPF,JCZQ_BF,JCZQ_JQS,JCZQ_BQC
				if (type == 'JCZQ') {
					if (item.lotterySubTypeEnum == 'JCZQ_SPF') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == "JCZQ_RQSPF") {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'JCZQ_BF') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'JCZQ_BQC') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'JCZQ_JQS') {
						return itemA.lotteryResultMsg + '球' == item.result
					} else {
						return false
					}
				} else if (type == 'BJDC') {
					if (item.lotterySubTypeEnum == 'BJDC_SFGG') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'BJDC_SPF') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'BJDC_JQS') {
						return itemA.lotteryResultMsg + '球' == item.result
					} else if (item.lotterySubTypeEnum == 'BJDC_BQC') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'BJDC_BF') {
						return itemA.lotteryResultMsg == item.result
					} else {
						return false
					}
				} else if (type == 'JCLQ') {
					if (item.lotterySubTypeEnum == 'JCLQ_SF') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'JCLQ_RFSF') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'JCLQ_DXF') {
						return itemA.lotteryResultMsg == item.result
					} else if (item.lotterySubTypeEnum == 'JCLQ_SFC') {
						return itemA.lotteryResultMsg == item.result
					} else {
						return false
					}
				}
			};
			// 复制
			const fCopyText = () => {
				let input = document.createElement("input"); // js创建一个input输入框
				// input.style="display: none";//设置样式
				input.value = oPlanDetail.value.planNo; // 将需要复制的文本赋值到创建的input输入框中
				document.body.appendChild(input); // 将输入框暂时创建到实例里面
				input.select(); // 选中输入框中的内容
				document.execCommand("Copy"); // 执行复制操作
				document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
				Toast({
					message: "复制成功",
					position: 'bottom'
				});
			}
			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';
					fGetPlanDetail();
				}
			});
			// //离开当前的组件，触发
			// onBeforeRouteLeave((to, from) => {
			// 	// console.log(to, from);
			// 	// const WHITE_LIST = ['/release', '/recharge'];
			// 	// // 如果不存在白名单则重定向
			// 	// if (WHITE_LIST.indexOf(to.path) === -1) {
			// 	// 	if (from.query.redirect && (to.path !== "/redirect" || to.name !== "Redirect")) {
			// 	// 		router.replace({
			// 	// 			name: 'Redirect',
			// 	// 			params: {
			// 	// 				path: from.query.redirect
			// 	// 			}
			// 	// 		});
			// 	// 	}
			// 	// }
			// })
			const plTan = () =>{
				oCPData.show = true
				oCPData.overlay= true
			}
			const showBut = () =>{
				if(oCPData.inputData && oCPData.inputData.length > 0){
					oCPData.inputShow = true
				}else {
					oCPData.inputShow = false
				}

			}
			const pinglunFun = () =>{
				saveComment({
					"commentText": oCPData.inputData,
					"entityId": route_query.id,
					"roleId": UserInfo.roleId,
					"type": "PLAN",
					"userId": UserInfo.userId
				}).then(()=>{
					Toast({
						type:'loading'
					});
					msgFun()
					oCPData.show = false
					oCPData.overlay= false
                    oCPData.inputData = ""
				})

			}
			const dianz = (agreeSign,e) =>{
				saveAgree({
					"agreeSign": agreeSign,
					"roleId": UserInfo.roleId,
					"type": "COMMENT",
					"userId": UserInfo.userId,
					entityId:e.commentId,
					gatherAgreeRoleId:e.commentRoleId,
					gatherAgreeUserId:e.commentUserId
				}).then(()=>{
					Toast({
						type:'loading'
					});
					msgFun()
				})

			}
			const toZhuye = (Id) =>{
				// 跳转到方案详情
				router.push({
					path: '/zhuye',
					query: {
						id: Id
					}
				})
			}
			const focusOnFun = () =>{
				focusOn({
					focusRoleId:oRoleDetail.value.roleId,
					roleId:UserInfo.roleId,
				}).then(res=>{
					console.log(res);
					if(res.success){
						Toast('关注成功');
						fGetPlanDetail()
					}

				})
			}
			const focusCancelFun = () =>{
				focusCancel({
					focusRoleId:oRoleDetail.value.roleId,
					roleId:UserInfo.roleId,
				}).then(res=>{
					console.log(res);
					if(res.success){
						Toast('取消关注');
						fGetPlanDetail()
					}

				})
			}
			const jvbaoK = () =>{
				listAccuseInfo().then(res=>{
					oCPData.jbData = res.result
					oCPData.isJb = true
				})

			}
			const jvbaoPl = () =>{
				listAccuseInfo().then(res=>{
					oCPData.jbData = res.result
					oCPData.jbShow = true
					oCPData.jbBverlay = true
				})

			}
			const zanFan = (e) =>{
				saveAgree({
					agreeSign:e,
					entityId:route_query.id,
					gatherAgreeRoleId:oRoleDetail.value.roleId,
					gatherAgreeUserId:oRoleDetail.value.userId,
					roleId:UserInfo.roleId,
					type:'PLAN',
					userId:UserInfo.userId
				}).then(()=>{
					fGetPlanDetail()
				})
			}
			const jvbaoG = () =>{
				oCPData.isJb = false
			}
			const jvbaoFun = () =>{
				oCPData.jbShow = true
				oCPData.jbBverlay = true
			}
			const toPlDetail = (e) =>{
				router.push({
					path: '/comment',
					query: {
						commentId:e.commentId,
						planId:route_query.id,
						type:'PLAN'
					}
				})
			}
			const jvbao = (e) =>{
				accuseReply({
					accuseInfo:e.informTypeName,
					accuseInfoId:e.informTypeId,
					beAccusedRoleId:oRoleDetail.value.roleId,
					beAccusedUserId:oRoleDetail.value.userId,
					entityId:route_query.id,
					roleId:UserInfo.roleId,
					type:'DECLARATION',
					userId:UserInfo.userId
				}).then(()=>{
					oCPData.jbShow = false
					oCPData.jbBverlay = false
					oCPData.isJb = false
					Toast('举报成功');
					fGetPlanDetail()
				})
			}
			const shanp = (e) =>{
				delReply({
					replyId:e.commentId,
					roleId:UserInfo.roleId,
					userId:UserInfo.userId
				}).then(()=>{
					Toast({
						type:'loading'
					});
					msgFun()
				})
			}
			const dialogC = reactive({
				isSure: false, // 确定退出
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "复制"
			});
			const toHy = () =>{
				fnCopyInnerText( route_query.id)
				if(UserInfo.iosAccountInfo){

					var userAgent = navigator.userAgent.toLowerCase();//获取UA信息
					// if(userAgent.indexOf("safari") != -1){
					// 	location.href =UserInfo.iosAccountInfo.h5Url + route_query.id
					// }else {
					// 	window.location.href ="lotteryshop://follow?orderId=" + route_query.id
					//
					// }
					if(userAgent.indexOf("mjfc") != -1 || userAgent.indexOf("fcone") != -1){
						window.location.href ="lotteryshop://follow?orderId=" + route_query.id
					}else {
						location.href =UserInfo.iosAccountInfo.h5Url + route_query.id
					}

				}else {
					dialogC.showDialog = true
				}

			}
			msgFun()
			return {
				defaultAvatar,
				jvbaoFun,
				dialogC,
				focusOnFun,
				jvbao,
				jvbaoK,
				jvbaoG,
				shanp,
				focusCancelFun,
				zanFan,
				toHy,
				getImg,
				dianz,
				toZhuye,
				jvbaoPl,
				toPlDetail,
				nActive,
				plTan,
				showBut,
				pinglunFun,

				UserInfo,
				oCPData,
				calculate_totalFee,
				oCalculate,
				oRoleDetail,
				oPlanDetail,
				oPayPlanDetail,
				oPayRelated,

				aOptimized,
				aTicketGroup,
				imgData,
				isDown,
				isApp,
				indexData,
				imaData,
				fBeginPay,
				fBeforeCloseActionSheetA,
				fSubmitActionSheetA,
				fBeforeClose,

				fGoRelease,

				fGoRedPack,
				fLeft,
				fnFormatData,
				fnMixTypes,
				fnPlanDetailsStatusCode,
				fnGoGeneralTxt,
				fResult,
				fFilterLotterySubTypeEnum,
				fCopyText,
				fbutFun,
				fbutsurFun
			}
		}
	}
</script>

<style scoped lang="scss">
	.planDetail {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		box-sizing: border-box;

		.planDetail_title {
			margin: 0.48rem 0.32rem;
			padding: 0.1066rem 0.4266rem;
			background: rgba(255, 118, 113, 0.15);
			border-radius: 0.3466rem;
			border: 0.0266rem solid #FF7671;

			font-size: 0.32rem;
			font-weight: 400;
			color: #E2335D;
			line-height: 0.4533rem;
		}

		.planDetail_body {
			padding: 0 0.32rem 1.0133rem;

			.select_clearCSS {
				width: fit-content;
				margin: 0.08rem auto 0;
				font-size: 0.2933rem;
				color: #666666;
			}

			.planDetail_userInfo {
				margin-top: 0.32rem;
				padding: 0.56rem 0.4266rem 0.4266rem;
				border-radius: 0.2133rem;
				overflow: hidden;
				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;

				.planDetail_user {
					display: flex;
					align-items: flex-start;

					.planDetail_user_left {
						display: flex;
						align-items: center;

						.planDetail_user_headPortrait {
							width: 1.28rem;
							height: 1.28rem;
							border-radius: 50%;
							margin-right: 0.64rem;
						}

						.planDetail_user_info {
							display: flex;
							flex-direction: column;
							.planDetail_user_info_top {
								display: flex;
								justify-content: space-between;
								align-items: center;
								.planDetail_user_info_top_l{
									display: flex;
									align-items: center;
									.planDetail_user_info_top_name {
										font-size: 0.3733rem;
										font-weight: 500;
										color: #383838;
										line-height: 0.5333rem;
										margin-right: 0.1066rem;
									}

									.planDetail_user_info_top_red {
										position: relative;
										display: flex;
										align-items: center;
										padding: 0.0266rem 0.2rem;
										height: 0.4266rem;
										border-radius: 0.2133rem;
										background-image: url('~@/assets/icon/icon-002.png');
										background-size: 100% 100%;
										background-repeat: no-repeat;

										.planDetail_user_info_top_red_img {
											position: absolute;
											width: auto;
											height: 0.24rem;
											top: -0.24rem;
											left: 0.1866rem;
										}

										&>em {
											font-size: 0.32rem;
											font-weight: bold;
											color: #E2335D;
											margin-right: 0.0266rem;
										}

										&>span {
											font-size: 0.2133rem;
											font-weight: 500;
											color: #383838;
										}
									}
								}

							}

							.planDetail_user_info_tip {
								display: flex;
								align-items: center;
								margin-top: 0.2133rem;

								.planDetail_user_info_tip_a {
									font-size: 0.32rem;
									font-weight: 400;
									color: #383838;
									margin-right: 0.12rem;
								}

								.planDetail_user_info_tip_b {
									font-size: 0.48rem;
									font-weight: bold;
									color: #FF7671;
								}
							}
						}
					}

					.planDetail_user_right {
						flex: 1;
						display: flex;
						justify-content: flex-end;

						.user_r{
							width: 1.733rem;
							height: 0.587rem;
							background: #FF7671;
							border-radius: 0.293rem;
							font-size: 0.32rem;
							font-weight: 500;
							color: #FFFFFF;
							line-height:  0.587rem;
							text-align: center;
						}
						.user_r1{
							border: 0.027rem solid #979797;
							color: #979797;
							background: rgba(0,0,0,0);
						}
					}
				}

				.planDetail_record {
					margin-top: 0.4266rem;
					padding-top: 0.4266rem;
					border-top: 0.0266rem solid #EAEAEA;
					display: flex;
					align-items: flex-start;
					overflow: hidden;
					position: relative;

					&>img {
						width: 0.64rem;
						height: auto;
						margin-right: 0.2666rem;
					}
				}

				.planDetail_record::after {
					content: "";
					position: absolute;
					right: 0;
					top: 0.1066rem;
					bottom: 0;
					margin: auto 0;
					width: 0.9333rem;
					height: 1.0933rem;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
				}
			}
		}

		.planDetail_planInfo {
			margin-top: 0.4266rem;
			padding: 0.32rem;
			background: #FFFFFF;
			border-radius: 0.2133rem;



			.planDetail_planInfo_title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.planDetail_planInfo_titl_box{
					display: flex;
					align-items: center;
					.planDetail_planInfo_title_border--blue {
						width: 0.1066rem;
						height: 0.48rem;
						background: #033EC2;
						border-radius: 0.0533rem;
						margin-right: 0.2133rem;
					}

					.planDetail_planInfo_title_border--red {
						width: 0.1066rem;
						height: 0.48rem;
						background: #F21B8E;
						border-radius: 0.0533rem;
						margin-right: 0.2133rem;
					}

					.planDetail_planInfo_title_border--purple {
						width: 0.1066rem;
						height: 0.48rem;
						background: #AA1AE1;
						border-radius: 0.0533rem;
						margin-right: 0.2133rem;
					}

					.planDetail_planInfo_title_a {
						font-size: 0.4266rem;
						font-weight: 600;
						color: #383838;
						margin-right: 0.1066rem;
					}

					.planDetail_planInfo_title_b {
						font-size: 0.3733rem;
						font-weight: 400;
						color: #383838;
					}
				}
				.planDetail_planInfo_titl_box_r{
					.img1{
						width: 0.48rem;
						height: 0.48rem;
						float: right;
						margin-right: 0.107rem;
					}

					.img2{
						width: 0.48rem;
						height: 0.48rem;
						margin-right: 0.427rem;
						float: right;
					}
					.font{
						margin-right: 0.16rem;
						font-size: 0.373rem;
						font-weight: 400;
						/*color: #FF7671;*/
						color: #B4B4B4;
						float: right;
					}
					.img3{
						width: 0.267rem;
						height: 0.16rem;
						float: right;
						margin-top: 0.16rem;
					}
					.tan_box{
						position: relative;
						.tan{
							padding: 0.533rem 0 0.267rem 0;
							box-sizing: border-box;
							display: flex;
							align-items: center;
							flex-direction: column;
							width: 0.907rem;
							height: 1.893rem;
							background: #FFFFFF;
							box-shadow: 0px 0.053rem 0.267rem 0px rgba(224,228,246,0.8);
							border-radius: 0.213rem;
							position: absolute;
							top: -0.32rem;
							right: -0.32rem;
							font-size: 0.32rem;
							font-weight: 500;
							color: #FF7671;
							img{
								width: 0.267rem;
								height: 0.16rem;
								margin-bottom: 0.48rem;
							}
						}
					}
				}

			}


			.planDetail_planInfo_info {
				.planDetail_planInfo_info_div {
					padding: 0.32rem 0.32rem 0;
					background: #FCFCFC;
					box-shadow: 0px 0.1066rem 0.4266rem 0px rgba(199, 202, 216, 0.2);
					border: 2px solid #FFFFFF;

					.planDetail_planInfo_info_title {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.planDetail_planInfo_info_title_left {
							.planDetail_planInfo_info_title_left_a {
								display: flex;
								align-items: center;

								.planDetail_planInfo_info_title_left_lable {
									font-size: 0.3733rem;
									font-weight: 400;
									color: #383838;
									margin-right: 0.1866rem;
								}

								.planDetail_planInfo_info_title_left_value {
									font-size: 0.48rem;
									font-weight: bold;
									color: #E2335D;
								}
							}

							.planDetail_planInfo_info_title_left_b {
								display: flex;
								align-items: center;
								margin-top: 0.2133rem;

								.planDetail_planInfo_info_title_left_lable {
									font-size: 0.3733rem;
									font-weight: 400;
									color: #383838;
									margin-right: 0.1866rem;
								}

								.planDetail_planInfo_info_title_left_value {
									font-size: 0.48rem;
									font-weight: bold;
									color: #383838;
								}
							}

							.planDetail_planInfo_info_title_left_time {
								margin-top: 0.1066rem;
								font-size: 0.32rem;
								font-weight: 400;
								color: #979797;
							}
						}

						.planDetail_planInfo_info_title_right {
							margin-top: -0.1066rem;
							margin-right: -0.1066rem;
							width: 1.5733rem;
							height: 1.52rem;
							border-radius: 50%;
						}

						.planDetail_planInfo_info_title_right--status {
							padding: 0.1066rem 0.4rem;
							background: #FF7671;
							border-radius: 0.2133rem 0.1066rem 0.2133rem 0.1066rem;

							font-size: 0.3733rem;
							font-weight: 400;
							color: #FFFFFF;
						}
					}

					.planDetail_planInfo_way--optimize {
						display: inline-block;
						margin-top: 0.4533rem;
						font-size: 0.2666rem;
						font-weight: 600;
						color: #383838;
						padding: 0.0533rem 0.1066rem;
						background: #FED530;
						border-radius: 0.24rem;
					}

					.planDetail_planInfo_way {
						margin-top: 0.4533rem;

						&>span {
							display: inline-block;
							text-align: center;
							width: 1.4933rem;
							background: #FF7671;
							border-radius: 0.1066rem;
							font-size: 0.32rem;
							color: #FFFFFF;
							line-height: 0.4533rem;
							margin-right: 0.1066rem;
							margin-top: 0.1066rem;
						}
					}

					.planDetail_planInfo_bet {
						margin-top: 0.3466rem;
						border-top: 0.0266rem solid #EAEAEA;
						display: flex;
						align-items: center;

						&>div {
							flex: 1;
							display: flex;
							flex-direction: column;
							align-items: center;
							padding: 0.2933rem 0;

							&>span:first-child {
								font-size: 0.3733rem;
								font-weight: bold;
								color: #383838;
							}

							&>span:last-child {
								margin-top: 0.1066rem;
								font-size: 0.32rem;
								transform: scale(0.8333);
								font-weight: 400;
								color: #979797;
							}
						}
					}

				}

				.planDetail_planInfo_describe {
					margin-top: 0.2133rem;
					box-sizing: border-box;
					height: 1.4933rem;
					padding: 0.3733rem 0.4266rem 0.2133rem;
					background-image: url('~@/assets/images/img_009.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;

					font-size: 0.32rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.4533rem;


					&>p {
						word-break: break-all;
						text-overflow: ellipsis;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
		}

		.planDetail_way {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #FFFFFF;
			border-radius: 0.2133rem;
			padding: 0.32rem;
			margin-top: 0.4266rem;

			.planDetail_way_left {
				display: flex;
				align-items: center;

				.planDetail_way_left_border--blue {
					width: 0.1066rem;
					height: 0.48rem;
					background: #033EC2;
					border-radius: 0.0533rem;
				}

				.planDetail_way_left_border--red {
					width: 0.1066rem;
					height: 0.48rem;
					background: #F21B8E;
					border-radius: 0.0533rem;
				}

				.planDetail_way_left_border--purple {
					width: 0.1066rem;
					height: 0.48rem;
					background: #AA1AE1;
					border-radius: 0.0533rem;
				}

				.planDetail_way_left_a {
					font-size: 0.4266rem;
					font-weight: 600;
					color: #383838;
					margin-left: 0.2133rem;
				}

				.planDetail_way_left_b--red {
					margin-left: 0.2666rem;
					padding: 0 0.0533rem;
					background: rgb(242, 27, 143);
					border-radius: 0.1066rem;

					font-size: 0.3733rem;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 0.5333rem;
				}

				.planDetail_way_left_b--yellow {
					margin-left: 0.2666rem;
					padding: 0 0.0533rem;
					background: #FED530;
					border-radius: 0.1066rem;

					font-size: 0.3733rem;
					font-weight: 500;
					color: #383838;
					line-height: 0.5333rem;
				}

				.planDetail_way_left_c {
					margin-left: 0.2666rem;

					font-size: 0.3733rem;
					font-weight: 500;
					color: #383838;
					line-height: 0.48rem;
				}
			}

			.planDetail_way_right {
				&>span {
					font-size: 0.3733rem;
					font-weight: 400;
					color: #383838;
				}

				&>img {
					width: auto;
					height: 0.2666rem;
					margin-left: 0.2133rem;
				}
			}
		}

		.planDetail_betInfo {
			padding: 0.32rem;
			background: #FFFFFF;
			border-radius: 0.2133rem;
			margin-top: 0.4266rem;

			.planDetail_betInfo_title {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.planDetail_betInfo_title_left {
					display: flex;
					align-items: center;

					.planDetail_betInfo_title_left_border--blue {
						width: 0.1066rem;
						height: 0.48rem;
						background: #033EC2;
						border-radius: 0.0533rem;
					}

					.planDetail_betInfo_title_left_border--red {
						width: 0.1066rem;
						height: 0.48rem;
						background: #F21B8E;
						border-radius: 0.0533rem;
					}

					.planDetail_betInfo_title_left_border--purple {
						width: 0.1066rem;
						height: 0.48rem;
						background: #AA1AE1;
						border-radius: 0.0533rem;
					}

					.planDetail_betInfo_title_left_a {
						font-size: 0.4266rem;
						font-weight: 600;
						color: #383838;
						margin-left: 0.2133rem;
					}
				}

				.planDetail_betInfo_title_right {
					.planDetail_betInfo_title_right_id {
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
					}

					.planDetail_betInfo_title_right_copy {
						margin-left: 0.1066rem;
						font-size: 0.32rem;
						font-weight: 500;
						color: #383838;
					}
				}
			}

			.planDetail_betInfo_body {
				margin-top: 0.6933rem;
				box-shadow: 0px 0.1066rem 0.4266rem 0px rgba(199, 202, 216, 0.2);
				border-radius: 0.1066rem;

				.planDetail_betInfo_body_mask {
					width: 100%;
					height: auto;
				}

				.planDetail_betInfo_body_option {
					margin-top: 0.4266rem;

					// filter:blur(2.5px);//毛玻璃这一个就够了
					&>li {
						display: flex;
						// align-items: center;
						border-bottom: 0.0266rem solid #F1F1F1;

						&>div {
							flex: 1;
							text-align: center;
							background: #FFFFFF;
							border-left: 0.0266rem solid #F1F1F1;
						}

						.planDetail_dan {
							border-right: 0;
							width: 1.3333rem;
							flex-grow: 1;
							border-right: 0.0266rem solid #F1F1F1;
						}

						&>div:nth-child(4) {
							flex-grow: 3;
							// flex-shrink:0
						}

						&>div:nth-child(2) {
							flex-grow: 3;
						}
						&>div:nth-child(1),
						&>div:nth-child(3),
						&>div:nth-child(5) {
							flex-grow: 2;
						}
					}

					&>li:first-of-type {
						border-top: 0.0266rem solid #F1F1F1;

						&>div {
							background: #FAFAFA;
							height: 0.8rem;
							line-height: 0.8rem;
							font-size: 0.32rem;
							font-weight: 600;
							color: #383838;
						}
					}

					&>li:not(:first-of-type) {
						&>div {
							// display: inline-block;
							// height: 100%;
							// line-height: 0.8533rem;
							display: flex;
							flex-direction: column;
							justify-content: center;
							padding: 0.2666rem 0;

							&>p {
								font-size: 0.32rem;
								color: #777777;
							}

							&>p:not(:first-of-type) {
								margin-top: 0.08rem;
							}
						}

						&>div:nth-child(5) {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
						}

						.planDetail_betInfo_body_option_options {
							&>p:nth-child(2n+1) {
								margin-top: 0.2133rem;
							}

							&>p:nth-child(1) {
								margin-top: 0;
							}

							&_iswin {
								color: #E73243;
							}
						}
					}
				}

			}

			.planDetail_betInfo_body--other {
				margin-top: 0.6933rem;
			}

			.planDetail_betInfo_tip {
				display: flex;
				align-items: flex-start;
				margin-top: 0.24rem;

				&>em {
					font-size: 0.32rem;
					font-weight: 400;
					color: #E2335D;
				}

				&>p {
					margin-left: 0.1066rem;
					font-size: 0.2666rem;
					font-weight: 400;
					color: #979797;
					line-height: 0.48rem;
				}
			}
		}

		.planDetail_otherInfo {
			margin-top: 0.4266rem;
			padding: 0 0.32rem 0.32rem;
			background: #FFFFFF;
			border-radius: 0.2133rem;

			.planDetail_otherInfo_mask {
				width: 100%;
				height: auto;
			}
		}


		.planDetail_bottom {
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0 0;
			padding: 0.4533rem 0.4266rem 0;

			.planDetail_form {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.16rem 0;
				margin-top: 0.2133rem;

				.planDetail_form_money {
					display: flex;
					align-items: center;
					font-size: 0.32rem;
					color: #383838;

					&>em {
						font-size: 0.48rem;
						font-weight: bold;
						color: #E2335D;
						margin-right: 0.1066rem;
					}
				}
			}
		}

		.planDetail_bottom_a {
			background: #FFFFFF;
			border-radius: 0.2133rem 0.2133rem 0 0;
			padding: 0.4533rem 0.4266rem;
		}

		.planDetail_tips {
			padding: 0.2666rem 0.64rem 0.88rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}

		.planDetail_dialogCD {
			text-align: center;
		}

	}
	.imgBut{
		position: absolute;
		left: 0;
		right: 0;
		top: 2.333rem;
		bottom: 0;
		margin: 0 auto;
	}
	.down-box{
		width: 100%;
		height: 1.067rem;
		position: fixed;
		left: 0;
		bottom: 4rem;
		z-index: 2007;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.down-icon{
			font-size: 1.067rem;
			color: #c8c9cc;
			margin-bottom: 0.267rem;
		}
		div{

			font-size: 0.4rem;
			color: #ffffff;
		}
	}
	.pinlun{
		width: 100%;
		/*height: 2.933rem;*/
		background: #FFFFFF;
		border-radius: 0.213rem;
		margin-top: 0.4266rem;
		padding: 0.373rem 0.453rem;
		box-sizing: border-box;
		.title{
			width: 100%;
			height: 0.48rem;
			display: flex;
			.shu{
				width: 0.107rem;
				height: 0.48rem;
				background: #033EC2;
				border-radius: 0.053rem;
				margin-right: 0.107rem;
			}
			.font{
				height: 0.48rem;
				font-size: 0.427rem;
				font-weight: 600;
				color: #383838;
				line-height: 0.48rem;
			}
		}
		.zwpl{
			width: 100%;
			height: 2.453rem;
			font-size: 0.373rem;
			font-weight: 400;
			color: #CCCCCC;
			line-height: 2.453rem;
			text-align: center;
		}
		.pl_box{
			width: 100%;
			height: 100%;
			padding-top: 0.427rem;
			box-sizing: border-box;
			.pl_item{
				width: 100%;
				display: flex;
				margin-bottom: 0.593rem;

				.pl_item_l{
					width: 1.013rem;
					margin-right: 0.32rem;
					img{
						width: 100%;
						height: 1.013rem;
						border-radius: 0.507rem;
					}
				}
				.pl_item_r{
					border-bottom: 0.013rem solid rgba(234, 234, 234, 1);
					width: calc(100% - 1.013rem - 0.32rem);
					.item_r_top{
						width: 100%;
						height: 0.133rem;
						display: flex;
						justify-content: space-between;
						margin-bottom: 0.32rem;
						align-items: center;
						.r_top_name{
							font-size: 0.373rem;
							font-weight: 400;
							color: #383838;
							line-height: 0.133rem;
						}
						.r_top_img{
							display: flex;
							align-items: center;
							img{
								width: 0.373rem;
								height: 0.34rem;
								margin-right: 0.067rem;
							}
							div{
								font-size: 0.32rem;
								font-weight: 400;
								color: #979797;
							}
						}
					}
					.item_zhu{
						margin-bottom: 0.32rem;
						width: 100%;
						max-height: 176px;
						font-size: 0.373rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.587rem;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						-webkit-box-orient: vertical;
					}
					.item_gd{
						margin-bottom: 0.32rem;
						width: 100%;
						height: 0.88rem;
						background: #FAFAFA;
						border-radius: 0.107rem;
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.88rem;
						padding-left: 0.427rem;
						box-sizing: border-box;
					}
					.item_bot{
						width: 100%;
						height: 0.373rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 0.32rem;
						div{
							font-size: 0.267rem;
							font-weight: 400;
							color: #979797;
							line-height: 0.373rem;
						}
					}
				}
			}
		}
	}
	.pinlum_but{
		width: 100%;
		height: 1.6rem;
		border-radius: 0.213rem 0.213rem 0px 0px;
		background: #ffffff;
		display: flex;
		padding: 0 0.427rem 0 0.96rem;
		box-sizing: border-box;
		align-items: center;
		img{
			width: 0.48rem;
			height: 0.48rem;
		}
		.bei{
			font-size: 0.32rem;
			font-weight: bold;
			color: #E2335D;
		}
		.but{
			width: 2.667rem;
			height: 1.013rem;
			background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
			border-radius: 0.653rem;
			font-size: 0.373rem;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 1.013rem;
			text-align: center;
		}
	}
	.tan_put{
		.tan_put_box{
			width: 100%;
			height: 100%;
			padding: 0 0.32rem;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			input{
				width: calc(100% - 3.147rem);
				height:0.853rem;
				background: #FFFFFF;
				border-radius: 0.453rem;
				border: 0.027rem solid #E6E6E6;
				padding: 0 0.32rem;
				box-sizing: border-box;
				font-size: 0.373rem;
				font-weight: 400;
				color: #383838;
				line-height: 0.853rem;
			}
			div{
				width: 2.72rem;
				height: 0.853rem;
				background: #FFABA8;
				border-radius: 0.427rem;
				line-height: 0.853rem;
				text-align: center;
				font-size: 0.373rem;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
	}

	#HTableB{
		.HTableB_ul{
			&>li{
				display: flex;
				align-items: stretch;
				justify-content: space-around;
				text-align: center;
				border-bottom: 1px solid #E6E6E6;
				&>span{
					// height: 100%;
					// line-height: 0.8533rem;
					width:calc(100% / 3);
					flex-grow:1;
					flex-shrink:0;
					border-right: 1px solid #E6E6E6;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
				}
				&>span:first-of-type{
					flex-grow:2;
					border-left: 1px solid #E6E6E6;
					box-sizing: border-box;
				}
				&>span:nth-child(2),&>span:nth-child(4){
					flex-grow:0
				}
			}
			&>li:first-of-type{
				height: 0.8533rem;
				border-top: 1px solid #E6E6E6;
				font-size: 0.3733rem;
				font-weight: 500;
				color: #333333;
				background-color: #F5FAFF;
			}
			&>li:not(:first-of-type){
				font-size: 0.3466rem;
				font-weight: 500;
				color: #666666;
				line-height: 0.4rem;
				&>span{
					padding: 0.2933rem 0;
				}
			}
		}
		.HTableB_bet_sessions{
			.HTableB_bet_sessions_title{
				line-height: 0.48rem;
			}
			.HTableB_bet_sessions_option{
				margin: 0.0533rem 0;
				font-size: 0.2933rem;
				font-weight: 500;
				line-height: 0.4266rem;
				color: #999999;
			}
		}
	}
	.jb_box{
		padding: 1.333rem 0.427rem 0;
		box-sizing: border-box;
		.jb_item{
			width: 100%;
			height: 1.333rem;
			background: rgba(255,255,255,0);
			font-size: 0.373rem;
			font-weight: 400;
			color: #383838;
			line-height: 1.333rem;
			border-bottom: 0.027rem solid rgba(250, 250, 250, 1);

		}
	}

</style>
