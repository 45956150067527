<template>
    <div class="comment">
        <div class="app_title_b">
            <van-sticky>
                <van-nav-bar :title="oCPData.title" left-arrow @click-left="fLeft" />
            </van-sticky>

        </div>
        <div class="tou">
            <div class="tou_l">
                <img :src="oCPData.touMsgData.commentPic" alt="" v-if="oCPData.touMsgData.commentPic" @click="toZhuye(oCPData.touMsgData.commentRoleId)">
                <img src="~@/assets/images/new/new_019.png" alt="" v-else @click="toZhuye(oCPData.touMsgData.commentRoleId)">
            </div>
            <div class="tou_r">
                <div class="tou_r_top">
                    <div class="tou_r_top_l">
                        <div class="tou_r_top_l_t_name">
                            {{oCPData.touMsgData.commentName}}
                        </div>
                        <div class="tou_r_top_l_t_title">
                            楼主
                        </div>
                        <div class="tou_r_top_l_t">

                        </div>

                    </div>
                    <template v-if="oCPData.touMsgData.canFocus">
                        <div class="user_r" v-if="!oCPData.touMsgData.focusSign" @click="focusOnFun">
                            + 关注
                        </div>
                        <div class="user_r user_r1" v-if="oCPData.touMsgData.focusSign" @click="focusCancelFun">
                            √ 关注
                        </div>
                    </template>
                </div>
                <div class="tou_r_conter">
                    <div>
                        {{oCPData.touMsgData.replyText}}
                    </div>

                </div>
                <div class="conter_box_r_bot">
                    <div class="conter_box_r_bot_shi">
                        {{oCPData.touMsgData.commentTime}}
                    </div>
                    <div class="conter_box_r_bot_del" style="color: #FF7671;" v-if="oCPData.touMsgData.commentRoleId == oCPData.roleId" @click="deletePl(oCPData.touMsgData,'tou')">
                        删除
                    </div>
                    <div class="conter_box_r_bot_del" style="color: #FF7671;" v-else @click="jvbaoK(oCPData.touMsgData)">
                        举报
                    </div>
                </div>
                <div class="tou_r_bot">
                    <div class="tou_r_bot_l">
                        <template  v-for="(item,index) in oCPData.agreeList" :key="index">
                            <template v-if="index < 3">
                                <img :src="item.rolePic" alt="" v-if="item.rolePic" @click="toZhuye(item.roleId)">
                                <img src="~@/assets/images/new/new_019.png" alt="" v-else @click="toZhuye(item.roleId)">
                            </template>

                        </template>

                        <div v-if=" oCPData.agreeList.length > 0" @click="tofsPlan">
                            {{oCPData.agreeList.length}}人赞过 >
                        </div>
                    </div>
                    <img src="~@/assets/images/new/ic_017.png" alt="" class="tou_r_bot_r" v-if="oCPData.touMsgData.agreeSign" @click="zanFan(false)">
                    <img src="~@/assets/images/new/ic_016.png" alt="" class="tou_r_bot_r" v-else @click="zanFan(true)">
                </div>
            </div>
        </div>
        <div class="conter">
            <div class="conter_title">
                全部回复 {{oCPData.msgTotal}}
            </div>
            <div class="conter_box_box">
                <div class="conter_box" v-for="(item,index) in oCPData.msgData" :key="index">
                    <div class="conter_box_l">
                        <img :src="item.commentPic" alt="" v-if="item.commentPic" @click="toZhuye(item.commentRoleId)">
                        <img src="~@/assets/images/new/new_019.png" alt="" v-else @click="toZhuye(item.commentRoleId)">
                    </div>
                    <div class="conter_box_r">
                        <div class="conter_box_r_top">
                            <div class="conter_box_r_top_name">
                                {{item.commentRoleName}}
                            </div>
                            <div class="conter_box_r_top_zan">
                                <img src="~@/assets/images/new/ic_017.png" alt="" v-if="item.agreeSign" @click="zanFan1(false,item)">
                                <img src="~@/assets/images/new/ic_016.png" alt="" v-else @click="zanFan1(true,item)">
                                <div>{{item.commentAgreeNum}}</div>
                            </div>
                        </div>
                        <div class="conter_box_lz" v-if="item.replyText" @click="huifu2(item)" >
                            <template v-if="item.replyDelSign">
                                <span style="color: #383838;">{{item.replyRoleName}}：</span>原文内容已删除
                            </template>
                            <template v-else>
                                <span style="color: #383838;">{{item.replyRoleName}}：</span>{{item.replyText}}
                            </template>

                        </div>
                        <div class="conter_box_r_con" @click="huifu1(item)">
                            {{item.commentText}}
                        </div>
                        <div class="conter_box_r_bot">
                            <div class="conter_box_r_bot_shi">
                                {{item.commentTime}}
                            </div>
                            <div class="conter_box_r_bot_del" style="color: #FF7671;" v-if="item.commentRoleId == oCPData.roleId" @click="deletePl(item)">
                                删除
                            </div>
                            <div class="conter_box_r_bot_del" style="color: #FF7671;" v-else @click="jvbaoK(item)">
                                举报
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wsj_xob" v-if="oCPData.msgData.length == 0">
                    <img src="~@/assets/images/img_021.png" alt="" >
                    <div>暂无数据</div>
                </div>
            </div>

        </div>
        <div class="bot">
            <div class="bot_box">
                <div class="bot_l" @click="huifu">
                    回复：{{oCPData.touMsgData.commentName}}
                </div>
                <div class="bot_r">
                    发布
                </div>
            </div>
        </div>
        <!--		举报弹出层-->
        <van-popup v-model:show="oCPData.jbShow" position="bottom" class="tan_put"   closeable :overlay="oCPData.jbBverlay">
            <div class="jb_box">
                <div class="jb_item" v-for="(item,index) in oCPData.jbData" :key="index" @click="jvbao(item)">
                    {{item.informTypeName}}
                </div>
            </div>
        </van-popup>

<!--        评论弹出层-->
        <van-popup v-model:show="oCPData.show" position="bottom" class="tan_put" :style="{ height: '1.6rem' }"  :overlay="oCPData.overlay">
            <div class="tan_put_box">
                <input type="text" placeholder="请输入评论内容" v-model="oCPData.inputData" @input="showBut">
                <div :style="oCPData.inputShow ? 'background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);' : ''" @click="pinglunFun">发布</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {
        queryReplyDetail,
        listReply,
        saveAgree,
        listAccuseInfo,
        accuseReply,
        saveComment,
        delReply
    } from '@/api/plan.js';
    import {
        focusOn,
        focusCancel
    } from '@/api/home.js';
    import {useStore} from "vuex";
    // import {useRoute, useRouter} from "vue-router";
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    import {onActivated, reactive} from "vue";
    import {useRoute,useRouter} from "vue-router";
    import {Toast} from "vant";
    export default {
        name: "comment",
        setup () {
            const store = useStore();
            // const route = useRoute();
            const route = useRoute();
            const route_query = route.query;
            const UserInfo = store.state.userInfo.userInfo;
            const router = useRouter();
            const oCPData = reactive({
                title:'0条回复',
                msgData:[],
                msgTotal:0,
                touMsgData:{},
                agreeList:[],
                isJb:false,
                jbShow:false,
                jbData:[],
                Titem:{},
                show:false,
                inputData:'',
                overlay:false,
                inputShow:false,
                TentityId:"",
                islouzhu:false,
                roleId:UserInfo.roleId,
                commentId:''
            })
            console.log(route_query.commentId);
            console.log(route_query);
            // 获取消息列表
            const msgFun = () =>{
                Toast({
                    type:'loading'
                });
                queryReplyDetail({
                    "current": 1,
                    "entityId": oCPData.commentId,
                    "jumpType": "NORMAL",
                    "roleId": UserInfo.roleId,
                    "size": 20,
                    "type": "COMMENT",
                    "userId": UserInfo.userId
                }).then(res=>{
                    oCPData.touMsgData = res.result
                    oCPData.agreeList = res.result.agreeList ? res.result.agreeList : []
                })
            };
            const msgFun1 = () =>{
                Toast({
                    type:'loading'
                });
                listReply({
                    "current": 1,
                    "entityId": oCPData.commentId,
                    "jumpType": "NORMAL",
                    "roleId": UserInfo.roleId,
                    "size": 20,
                    "type":  route_query.type,
                    "userId": UserInfo.userId
                }).then(res=>{
                    oCPData.msgData = res.result.records
                    oCPData.msgTotal = res.result.total
                    oCPData.title = res.result.total + "条回复"
                })
            };
            const toZhuye = (Id) =>{
                // 跳转到方案详情
                router.push({
                    path: '/zhuye',
                    query: {
                        id: Id
                    }
                })
            }
            const zanFan = (e) =>{
                saveAgree({
                    agreeSign:e,
                    entityId:oCPData.touMsgData.commentId,
                    gatherAgreeRoleId:oCPData.touMsgData.commentRoleId,
                    gatherAgreeUserId:oCPData.touMsgData.commentUserId,
                    roleId:UserInfo.roleId,
                    type:'COMMENT',
                    userId:UserInfo.userId
                }).then(()=>{
                    msgFun()
                })
            }
            const zanFan1 = (e,item) =>{
                saveAgree({
                    agreeSign:e,
                    entityId:item.commentId,
                    gatherAgreeRoleId:item.commentRoleId,
                    gatherAgreeUserId:item.commentUserId,
                    roleId:UserInfo.roleId,
                    type:'COMMENT',
                    userId:UserInfo.userId
                }).then(()=>{
                    msgFun1()
                })
            }

            const jvbaoK = (e,item) =>{
                if(item == 'tou'){
                    Toast('不能举报自己')
                }else {
                    oCPData.Titem = e
                    listAccuseInfo().then(res=>{
                        oCPData.jbData = res.result
                        oCPData.jbShow = true
                    })
                }


            }
            const focusCancelFun = () =>{
                focusCancel({
                    focusRoleId:oCPData.touMsgData.commentRoleId,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);
                    if(res.success){
                        msgFun()
                    }

                })
            }
            const focusOnFun = () =>{
                focusOn({
                    focusRoleId:oCPData.touMsgData.commentRoleId,
                    roleId:UserInfo.roleId,
                }).then(res=>{
                    console.log(res);
                    if(res.success){
                        msgFun()
                    }

                })
            }
            const jvbao = (e) =>{
                accuseReply({
                    accuseInfo:e.informTypeName,
                    accuseInfoId:e.informTypeId,
                    beAccusedRoleId:oCPData.Titem.commentRoleId,
                    beAccusedUserId:oCPData.Titem.commentUserId,
                    entityId:oCPData.Titem.commentId,
                    roleId:UserInfo.roleId,
                    type:'COMMENT',
                    userId:UserInfo.userId
                }).then(()=>{
                    oCPData.jbShow = false
                    oCPData.jbBverlay = false
                    oCPData.isJb = false
                    Toast('举报成功');
                    msgFun1()
                })
            }
            const showBut = () =>{
                if(oCPData.inputData && oCPData.inputData.length > 0){
                    oCPData.inputShow = true
                }else {
                    oCPData.inputShow = false
                }

            }
            const huifu = () =>{
                oCPData.show = true
                oCPData.inputData = ""
                oCPData.overlay= true
                oCPData.TentityId = oCPData.touMsgData.commentId
                oCPData.islouzhu = true

            }
            const huifu1 = (item) =>{
                oCPData.show = true
                oCPData.overlay= true
                oCPData.inputData = ""
                oCPData.TentityId = item.commentId
                oCPData.islouzhu = false

            }
            const huifu2 = (item) =>{
                oCPData.show = true
                oCPData.overlay= true
                oCPData.inputData = ""
                oCPData.TentityId = item.replyId
                oCPData.islouzhu = false

            }
            const pinglunFun = () =>{
                saveComment({
                    "commentText": oCPData.inputData,
                    "entityId":route_query.planId,
                    parentId:oCPData.touMsgData.commentId,
                    "commentId":oCPData.islouzhu ? undefined : oCPData.TentityId,
                    "roleId": UserInfo.roleId,
                    "type": route_query.type,
                    "userId": UserInfo.userId
                }).then(()=>{
                    Toast({
                        type:'loading'
                    });
                    msgFun1()
                    oCPData.show = false
                    oCPData.overlay= false
                    oCPData.inputData = ""
                    oCPData.islouzhu = false
                })

            }
            const deletePl = (e,item) =>{
                delReply({
                    replyId:e.commentId,
                    "roleId": UserInfo.roleId,
                    "userId": UserInfo.userId
                }).then(()=>{
                    if(item == 'tou'){
                        fLeft()
                    }
                    msgFun1()
                })
            }
            const tofsPlan = () =>{
                router.push({
                    path: '/fensiPlan',
                    query: {
                        commentId: oCPData.touMsgData.commentId,
                    }
                })
            }
            /*
           * fLeft van-nav-bar 左侧点击事件
           */
            const fLeft = () => {
                fnIsHasHistory("/plan");
            };
            // 生命周期
            onActivated(() => {
                const route = useRoute();
                const route_query = route.query;
                oCPData.commentId = route_query.commentId
                msgFun()
                msgFun1()
            });
            return{
                fLeft,
                showBut,
                tofsPlan,
                deletePl,
                oCPData,
                toZhuye,
                pinglunFun,
                huifu,
                huifu1,
                huifu2,
                zanFan,
                zanFan1,
                focusCancelFun,
                jvbaoK,
                focusOnFun,
                jvbao,

            }
        }
    }
</script>

<style scoped lang="scss">
    .comment{
        width: 100%;
        /*height: 100%;*/
        padding-bottom: 2.44rem;
        box-sizing: border-box;
        background: #F1F2F7;
        .tou{
            padding: 0.373rem 0.373rem 0 0.373rem;
            box-sizing: border-box;
            width: calc(100% - 0.747rem);
            margin: 0 auto 0.427rem;
            background: #FFFFFF;
            border-radius: 0.213rem;
            display: flex;
            .tou_l{
                width: 1.707rem;
                height: 100%;
                img{
                    width: 1.013rem;
                    height: 1.013rem;
                    border-radius: 0.5065rem;
                }
            }
            .tou_r{
                width: calc(100% - 1.707rem);
                height: 100%;
                .tou_r_top{
                    width: 100%;
                    height: 1.013rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .tou_r_top_l{
                        height: 100%;
                        display: flex;
                        align-items: center;
                            .tou_r_top_l_t_name{
                                font-size: 0.373rem;
                                font-weight: 400;
                                color: #383838;
                                margin-right: 0.107rem;
                            }
                            .tou_r_top_l_t_title{
                                width: 0.747rem;
                                height: 0.32rem;
                                background: #4A4A4A;
                                border-radius: 0.107rem;
                                text-align: center;
                                line-height: 0.32rem;
                                font-size: 0.267rem;
                                font-weight: 400;
                                color: #FFFFFF;
                            }

                        .tou_r_top_l_shi{
                            font-size: 0.267rem;
                            font-weight: 400;
                            color: #979797;
                        }
                    }
                    .user_r{
                        width: 1.733rem;
                        height: 0.587rem;
                        background: #FF7671;
                        border-radius: 0.293rem;
                        font-size: 0.32rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height:  0.587rem;
                        text-align: center;
                    }
                    .user_r1{
                        border: 0.027rem solid #979797;
                        color: #979797;
                        background: rgba(0,0,0,0);
                    }
                }
                .conter_box_r_bot{
                    margin-top: 0.107rem;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.32rem;
                    font-weight: 400;
                    color: #979797;
                    align-items: center;
                    padding: 0.32rem 0;
                    box-sizing: border-box;
                    border-bottom: 0.027rem solid #EAEAEA;
                }
                .tou_r_conter{

                    width: 100%;
                    max-height: 2.987rem;
                    div{
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        font-size: 0.373rem;
                        font-weight: 400;
                        color: #383838;
                        line-height: 0.587rem;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        line-clamp: 4;
                        -webkit-line-clamp: 4; //显示几行

                    }

                }
                .tou_r_bot{
                    width: 100%;
                    height: 1.227rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .tou_r_bot_l{
                        display: flex;
                        img{
                            width: 0.587rem;
                            height: 0.587rem;
                            border-radius: 0.293rem;
                            margin-right: 0.213rem;
                        }
                        div{
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #383838;
                            line-height: 0.587rem;
                        }
                    }
                    .tou_r_bot_r{
                        width: 0.373rem;
                        height: 0.347rem;
                    }
                }
            }
        }
        .conter{
            height: 13.84rem;
            background: #FFFFFF;
            border-radius: 0.213rem;
            width: calc(100% - 0.64rem);
            margin: 0 auto;
            padding: 0.373rem;
            box-sizing: border-box;

            .conter_title{
                width: 100%;
                height: 0.587rem;
                font-size: 0.427rem;
                font-weight: 600;
                color: #383838;
                line-height: 0.587rem;
                margin-bottom: 0.427rem;
            }
            .conter_box_box{
                height: calc(100% - 1.014rem);
                overflow-y: auto;
                .wsj_xob{
                    width: 100%;
                    height: 100%;
                    img{
                        width: calc(100% - 2.72rem);
                        height: auto;
                        margin:0 1.36rem ;
                    }
                    div{
                        width: 100%;
                        height: 0.453rem;
                        font-size: 0.32rem;
                        font-weight: 400;
                        color: #979797;
                        line-height: 0.453rem;
                        text-align: center;
                    }
                }
                .conter_box{
                    display: flex;
                    .conter_box_l{
                        width: 1.333rem;
                        height: 100%;
                        img{
                            width: 1.013rem;
                            height: 1.013rem;
                            border-radius: 0.507rem;
                            margin-right: 0.32rem;
                        }
                    }
                    .conter_box_r{
                        width: calc(100% - 1.333rem);
                        border-bottom: 0.027rem solid #EAEAEA;
                        padding-bottom: 0.32rem;
                        box-sizing: border-box;
                        .conter_box_r_top{
                            width: 100%;
                            height: 1.013rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .conter_box_r_top_name{
                                font-size: 0.373rem;
                                font-weight: 400;
                                color: #383838;
                            }
                            .conter_box_r_top_zan{
                                display: flex;
                                align-items: center;
                                img{
                                    width: 0.373rem;
                                    height: 0.347rem;
                                    margin-right: 0.107rem;
                                }
                                div{
                                    font-size: 0.32rem;
                                    font-weight: 400;
                                    color: #979797;
                                }
                            }
                        }
                        .conter_box_lz{
                            width: 100%;
                            background: #FAFAFA;
                            border-radius: 0.107rem;
                            padding: 0.427rem 0.213rem;
                            box-sizing: border-box;
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #979797;
                            line-height: 0.453rem;
                            margin-bottom: 0.32rem;
                        }
                        .conter_box_r_con{

                            font-size: 0.373rem;
                            font-weight: 400;
                            color: #383838;
                            line-height: 0.587rem;
                        }
                        .conter_box_r_bot{
                            margin-top: 0.107rem;
                            display: flex;
                            justify-content: space-between;
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #979797;
                            align-items: center;
                        }
                    }
                }
            }

        }

        .bot{
            width: 100%;
            height: 1.573rem;
            background: rgba(241, 242, 247, 1);
            position: fixed;
            left: 0;
            bottom: 0;
            .bot_box{
                width: 100%;
                height: 1.44rem;
                background: #FFFFFF;
                border-radius: 0.213rem 0.213rem 0px 0px;
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 0.32rem;
                box-sizing: border-box;
                .bot_l{
                    width: calc(100% - 3.147rem);
                    height: 0.907rem;
                    background: #FFFFFF;
                    border-radius: 0.453rem;
                    border: 0.027rem solid #E6E6E6;
                    line-height:  0.907rem;
                    padding: 0 0.32rem;
                    box-sizing: border-box;
                    font-size: 0.373rem;
                    font-weight: 400;
                    color: #D2D2D2;
                }
                .bot_r{
                    width: 2.72rem;
                    height: 0.853rem;
                    background: #FFABA8;
                    border-radius: 0.427rem;
                    font-size: 0.373rem;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height:  0.853rem;
                    text-align: center;
                }
            }
        }
        .jb_box{
            padding: 1.333rem 0.427rem 0;
            box-sizing: border-box;
            .jb_item{
                width: 100%;
                height: 1.333rem;
                background: rgba(255,255,255,0);
                font-size: 0.373rem;
                font-weight: 400;
                color: #383838;
                line-height: 1.333rem;
                border-bottom: 0.027rem solid rgba(250, 250, 250, 1);

            }
        }
        .tan_put{
            .tan_put_box{
                width: 100%;
                height: 100%;
                padding: 0 0.32rem;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                input{
                    width: calc(100% - 3.147rem);
                    height:0.853rem;
                    background: #FFFFFF;
                    border-radius: 0.453rem;
                    border: 0.027rem solid #E6E6E6;
                    padding: 0 0.32rem;
                    box-sizing: border-box;
                    font-size: 0.373rem;
                    font-weight: 400;
                    color: #383838;
                    line-height: 0.853rem;
                }
                div{
                    width: 2.72rem;
                    height: 0.853rem;
                    background: #FFABA8;
                    border-radius: 0.427rem;
                    line-height: 0.853rem;
                    text-align: center;
                    font-size: 0.373rem;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>
