<template>
	<div id="HTableB">
		<ul class="HTableB_ul">
			<li>
				<span v-for="(item, index) in aTitle" :key="index">{{item}}</span>
			</li>
			<li v-for="(item, index) in aContent" :key="index">
				<span v-for="(itemA,indexA) in aTitle" :key="indexA">
					<template v-if='indexA === 0'>
						<div class="HTableB_bet_sessions" v-for="(itemB,indexB) in item[Object.keys(item)[indexA]]" :key="indexB">
							<template v-if="bLotteryTypeEnum">
								<p v-if="itemB.handicap" class="HTableB_bet_sessions_title">{{itemB.homeTeamName}}({{itemB.handicap}})vs{{itemB.guestTeamName}}</p>
								<p v-else class="HTableB_bet_sessions_title">{{itemB.homeTeamName}}vs{{itemB.guestTeamName}}</p>
							</template>
							<template v-else>
								<p v-if="itemB.handicap" class="HTableB_bet_sessions_title">{{itemB.guestTeamName}}vs{{itemB.homeTeamName}}({{itemB.handicap}})</p>
								<p v-else class="HTableB_bet_sessions_title">{{itemB.guestTeamName}}vs{{itemB.homeTeamName}}</p>
							</template>
							<p class="HTableB_bet_sessions_option">{{itemB.playType}}【{{itemB.option}}】({{itemB.realOdds}})</p>
						</div>
					</template>
					<template v-else-if="Object.keys(item)[indexA] === 'winState'">
						<p :class="fState(item[Object.keys(item)[indexA]],'color')">{{fState(item[Object.keys(item)[indexA]])}}</p>
					</template>
					<template v-else>
						{{item[Object.keys(item)[indexA]]}}
					</template>
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
	export default{
		name:"HTableB",
		props: {
			aTitle: {
				type: Array,
				default: () => {
					return []
				}
			},
			aContent: {
				type: Array,
				default: () => {
					return []
				}
			},
			// 竞猜类型 true为足球,false为篮球
			bLotteryTypeEnum: {
				type: Boolean,
				default:true
			},
		},
		methods:{
			// 状态过滤
			fState(state,type){
				let tColor = "";
				let tStr = ""
				switch (state){
					case 'WIN':
						tColor = "cE73243";
						tStr = "已中奖"
						break;
					case 'UN_WIN':
						tColor = "";
						tStr = "未中奖"
						break;
					case 'UN_RUN':
						tColor = "cEDAD37";
						tStr = "待开奖"
						break;
					default:
						break;
				}
				if(type === 'color'){
					return tColor
				}else{
					return tStr
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	#HTableB{
		.HTableB_ul{
			&>li{
				display: flex;
				align-items: stretch;
				justify-content: space-around;
				text-align: center;
				border-bottom: 1px solid #E6E6E6;
				&>span{
					// height: 100%;
					// line-height: 0.8533rem;
					width: 0.96rem;
					flex-grow:1;
					flex-shrink:0;
					border-right: 1px solid #E6E6E6;
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
				}
				&>span:first-of-type{
					flex-grow:2;
					border-left: 1px solid #E6E6E6;
					box-sizing: border-box;
				}
				&>span:nth-child(2),&>span:nth-child(4){
					flex-grow:0
				}
			}
			&>li:first-of-type{
				height: 0.8533rem;
				border-top: 1px solid #E6E6E6;
				font-size: 0.3733rem;
				font-weight: 500;
				color: #333333;
				background-color: #F5FAFF;
			}
			&>li:not(:first-of-type){
				font-size: 0.3466rem;
				font-weight: 500;
				color: #666666;
				line-height: 0.4rem;
				&>span{
					padding: 0.2933rem 0;
				}
			}
		}
		.HTableB_bet_sessions{
			.HTableB_bet_sessions_title{
				line-height: 0.48rem;
			}
			.HTableB_bet_sessions_option{
				margin: 0.0533rem 0;
				font-size: 0.2933rem;
				font-weight: 500;
				line-height: 0.4266rem;
				color: #999999;
			}
		}
	}
</style>