<template>
	<div class="plan">
		<div class="plan_box">
			<div class="plan_top">
				<div class="plan_top_box" :style="isApp ? 'top: 1.3rem' : ''">
					<div class="plan_top_title_box">
						<div></div>
						<div>跟单大厅</div>
						<img src="~@/assets/images/new/new_013.png" alt="" @click="fGoPath('searchPlan')">
					</div>
					<div class="plan_top_bot">
						<div class="plan_top_bot_item" v-for="(item,index) in oCPData.godData" :key="index" @click="toZhuye(item.roleId)">
							<div class="dashen_item_box">
								<div class="dashen_box">
									<img :src="item.headPicture" alt="" v-if="item.headPicture">
									<img src="~@/assets/images/new/new_019.png" alt="" v-else>
									<div  v-if="item.count > 0">{{item.count}}</div>
								</div>

							</div>
							<div class="dashen_item_title">
								{{item.roleName}}
							</div>
						</div>
					</div>
				</div>

				<img src="~@/assets/images/new/new_012.png" alt="" class="plan_tou_back" :style="isApp ? 'height: 7.7rem;' : ''">
			</div>
			<div class="gendan">
				<div class="bang_top">
					<div style="width: 30%"></div>
					<div class="zhog">
						<div class="bang_title_box" style="margin-right: 0.427rem" @click="bangFun">
							<div class="bang_title" :style="oCPData.bang.styleBang1">
								全部
								<img v-if="!oCPData.bang.isBang" src="~@/assets/images/new/new_004.png" alt="">
							</div>
						</div>
						<div class="bang_title_box" @click="bangFun1">
							<div class="bang_title"  :style="oCPData.bang.styleBang2">
								关注
								<img v-if="oCPData.bang.isBang" src="~@/assets/images/new/new_004.png" alt="">
							</div>
						</div>
					</div>
					<div class="you">
						<div class="HDropdownMenu_show" :class="{'HDropdownMenu_show--select' : oCPData.select.name !== '全部'}" @click="fOpenPlanStatus">
							<span>{{oCPData.select.name !== "全部" ? oCPData.select.name : "筛选" }}</span>
							<img v-if="oCPData.select.name === '全部'" src="~@/assets/icon/icon-023.png">
							<img v-else src="~@/assets/icon/icon-026.png">
						</div>
						<div class="HDropdownMenu_content" v-if="oCPData.show">
							<div class="HDropdownMenu_content_title" @click="fClosePlanStatus">
								<span>筛选</span>
								<img src="~@/assets/icon/icon-024.png">
							</div>
							<p v-for="(item,index) in oCPData.option" :key="index" class="HDropdownMenu_content_option" :class="{'HDropdownMenu_content_option--selected' : item.name === oCPData.select.name}" @click="fSelectPlanStatus(item)">{{item.name}}</p>
						</div>
					</div>

				</div>
				<div class="gendan_hua"  @scroll="listenBottomOut" id="scroll" v-if="!oCPData.bang.isBang">
<!--					v-infinite-scroll="load" style="overflow: auto"-->
					<div class="gendan_item" v-for="(item,index) in oCPData.planList" :key="index">
						<div class="gendan_item_1" @click="toDetailFun(item.planId)">
							<div class="gen_item_title" v-if="item.lotteryType == 'JCZQ'">
								竞彩足球
							</div>
							<div class="gen_item_title" v-if="item.lotteryType == 'JCLQ'" style="background: #F21B8F">
								竞彩篮球
							</div>
							<div class="gen_item_info">
								<div class="gen_item_info_left" >
									<div class="info_1">
										<div class="gen_item_img">
											<img :src="item.portraitUrl" alt="" class="img_box_1" v-if="item.portraitUrl">
											<img src="~@/assets/images/new/new_019.png" alt="" v-else>
											<img src="~@/assets/images/new/new_018.png" alt="" class="img_box_2" v-if="item.hasRedpack">
<!--											<div class="img_box_font" v-if="item.evenRedCount > 0">{{item.evenRedCount}}</div>-->
										</div>
									</div>
									<div class="info_2">
										<div class="info_2_top">
											<div class="info_2_top_name">{{item.roleName}}</div>
											<div class="info_2_top_lian">
												<div class="info_2_top_lian1" v-if="item.evenRedCount > 0">
													<img src="~@/assets/images/new/new_005.png" alt="" class="info_2_top_lian2">
													<div class="info_2_top_lian3">
														<span class="info_2_top_lian4">{{item.evenRedCount}}</span> <span class="info_2_top_lian5">连胜</span>
													</div>
												</div>
											</div>
										</div>
										<div class="info_2_bot">
											截止时间：{{fnFormatData(item.stopCopy,"MM-dd hh:mm")}}
										</div>
									</div>
								</div>

								<div class="info_3">
									<div class="info_3_1">
										¥{{item.totalAmount}}
									</div>
									<div class="info_3_2">
										自购金额
									</div>
								</div>

							</div>

							<div class="gen_liuyan">
								<div class="gen_liuyan_box">
									<img src="~@/assets/images/new/new_014.png" alt="">
									<div class="liuyan_font">
										{{item.planName}}
									</div>
								</div>

							</div>

							<div class="gen_chuan">
								<div class="gen_chuan_item">
									<div class="gen_chuan_item_top">
										{{item.mixTypes}}
									</div>
									<div class="chuan_item_bot">
										串联方式
									</div>
								</div>
								<div class="gen_chuan_item_shu"></div>
								<div class="gen_chuan_item">
									<div class="chuan_item_top">
										{{item.heat}}
									</div>
									<div class="chuan_item_bot">
										热度
									</div>
								</div>
								<div class="gen_chuan_item_shu"></div>
								<div class="gen_chuan_item">
									<div class="chuan_item_top">
										{{item.profitProtectRate}}
									</div>
									<div class="chuan_item_bot">
										保障赔率
									</div>
								</div>
								<div class="gen_chuan_item_shu"></div>
								<div class="gen_chuan_item">
									<div class="chuan_item_top">
										{{item.rate}}%
									</div>
									<div class="chuan_item_bot">
										近7天回报率
									</div>
								</div>
							</div>
						</div>
						<div class="gendan_item_2">
							<div class="gendan_item_2_item" @click="toDetailFun(item.planId)">
								<img src="~@/assets/images/new/new_015.png" alt="">
								<div>{{item.commentNum}}</div>
							</div>
							<div class="gendan_item_2_item" >
								<img src="~@/assets/images/new/new_016.png" alt="" v-if="item.yesNo" @click="dianzFun(item,false)">
								<img src="~@/assets/images/new/ic_02.png" alt="" v-else @click="dianzFun(item,true)">
								<div>{{item.agreeNum}}</div>
							</div>
							<div class="gendan_item_2_item">
								<img src="~@/assets/images/new/new_017.png" alt="">
								<div>{{item.forwardNum}}</div>
							</div>
						</div>
					</div>
					<div class="wsj_xob" v-if="oCPData.planList.length == 0">
						<img src="~@/assets/images/img_021.png" alt="" >
						<div>暂无数据</div>
					</div>
				</div>
				<div class="gendan_hua"  @scroll="listenBottomOut1" id="scroll1" v-else >
					<!--					v-infinite-scroll="load" style="overflow: auto"-->
					<template v-for="(itemA,index) in oCPData.planListGuan " :key="index">
						<template v-if="itemA.release">
							<div class="gendan_item" v-for="(item,index) in itemA.planResponseList" :key="index">
								<div class="gendan_item_1" @click="toDetailFun(item.planId)">
									<div class="gen_item_title" v-if="item.lotteryType == 'JCZQ'">
										竞彩足球
									</div>
									<div class="gen_item_title" v-if="item.lotteryType == 'JCLQ'" style="background: #F21B8F">
										竞彩篮球
									</div>
									<div class="gen_item_info">
										<div class="gen_item_info_left">
											<div class="info_1">
												<div class="gen_item_img">
													<img :src="item.portraitUrl" alt="" class="img_box_1" v-if="item.portraitUrl">
													<img src="~@/assets/images/new/new_019.png" alt="" v-else>
													<img src="~@/assets/images/new/new_018.png" alt="" class="img_box_2" v-if="item.hasRedpack">
<!--													<div class="img_box_font">{{item.evenRedCount}}</div>-->
												</div>
											</div>
											<div class="info_2">
												<div class="info_2_top">
													<div class="info_2_top_name">{{item.roleName}}</div>
													<div class="info_2_top_lian">
														<div class="info_2_top_lian1" v-if="item.evenRedCount > 0">
															<img src="~@/assets/images/new/new_005.png" alt="" class="info_2_top_lian2">
															<div class="info_2_top_lian3">
																<span class="info_2_top_lian4">{{item.evenRedCount}}</span> <span class="info_2_top_lian5">连胜</span>
															</div>
														</div>
													</div>
												</div>
												<div class="info_2_bot">
													截止时间：{{fnFormatData(item.stopCopy,"MM-dd hh:mm")}}
												</div>
											</div>
										</div>

										<div class="info_3">
											<div class="info_3_1">
												¥{{item.totalAmount}}
											</div>
											<div class="info_3_2">
												自购金额
											</div>
										</div>

									</div>

									<div class="gen_liuyan">
										<div class="gen_liuyan_box">
											<img src="~@/assets/images/new/new_014.png" alt="">
											<div class="liuyan_font">
												{{item.planName}}
											</div>
										</div>

									</div>

									<div class="gen_chuan">
										<div class="gen_chuan_item">
											<div class="gen_chuan_item_top">
												{{item.mixTypes}}
											</div>
											<div class="chuan_item_bot">
												串联方式
											</div>
										</div>
										<div class="gen_chuan_item_shu"></div>
										<div class="gen_chuan_item">
											<div class="chuan_item_top">
												{{item.heat}}
											</div>
											<div class="chuan_item_bot">
												热度
											</div>
										</div>
										<div class="gen_chuan_item_shu"></div>
										<div class="gen_chuan_item">
											<div class="chuan_item_top">
												{{item.profitProtectRate}}
											</div>
											<div class="chuan_item_bot">
												保障赔率
											</div>
										</div>
										<div class="gen_chuan_item_shu"></div>
										<div class="gen_chuan_item">
											<div class="chuan_item_top">
												{{item.rate}}%
											</div>
											<div class="chuan_item_bot">
												近7天回报率
											</div>
										</div>
									</div>
								</div>
								<div class="gendan_item_2">
									<div class="gendan_item_2_item" @click="toDetailFun(item.planId)">
										<img src="~@/assets/images/new/new_015.png" alt="">
										<div>{{item.commentNum}}</div>
									</div>
									<div class="gendan_item_2_item">
										<img src="~@/assets/images/new/new_016.png" alt="" v-if="item.yesNo">
										<img src="~@/assets/images/new/ic_02.png" alt="" v-else>
										<div>{{item.agreeNum}}</div>
									</div>
									<div class="gendan_item_2_item">
										<img src="~@/assets/images/new/new_017.png" alt="">
										<div>{{item.forwardNum}}</div>
									</div>
								</div>
							</div>
						</template>
						<template v-if="!itemA.release">
							<div class="bang_item" @click="toZhuye(itemA.memberInfoResponse.focusRoleId)">
								<div class="bang_item_left_box">
									<div class="bang_item_left">
										<div class="bang_item_left_tou">
											<div class="tou">
												<img :src="itemA.memberInfoResponse.head" alt="" v-if="itemA.memberInfoResponse.head">
												<img src="~@/assets/images/new/new_019.png" alt=""  v-else>
											</div>
										</div>
										<div class="bang_item_left_name">{{itemA.memberInfoResponse.focusRoleName}}</div>
									</div>
									<div class="bang_item_right1" v-if="itemA.memberInfoResponse.evenRedCount > 0">
										<div>
											<img src="~@/assets/images/new/new_005.png" alt="">
											<div class="font_box" >
												<span style="font-size: 0.32rem;color: #E2335D;margin-right: 0.053rem" >{{itemA.memberInfoResponse.evenRedCount}}</span><span>连胜</span>
											</div>
										</div>



									</div>
								</div>
								<div class="bang_item_fon">
									未发单
								</div>

							</div>
						</template>
					</template>


						<div class="wsj_xob" v-if="oCPData.planListGuan.length == 0">
							<img src="~@/assets/images/img_021.png" alt="" >
							<div>暂无数据</div>
						</div>


				</div>

			</div>

		</div>
		<img src="~@/assets/images/new/new_031.png" alt="" class="tix" v-if="!oCPData.isTiShow">
		<img src="~@/assets/images/new/new_039.png" alt="" class="tix1" @click="tixin" v-if="!oCPData.isTiShow">

	</div>
</template>

<script>
	import {
		// ref,
		reactive,
		onActivated,
		inject
	} from 'vue';
	import {
		useStore
		// useStore
	} from "vuex";
	import {
		useRoute,
		useRouter
	} from "vue-router";
	import {
		Toast
	} from 'vant';
	// import HActionSheetA from "@/components/HActionSheet/HActionSheetA";
	// import HDialogC from "@/components/HDialog/HDialogC/index.vue";
	import {
		fnFormatData,
		fnPlanStatusCode,
		fnPlanStatusCodeColor,
		uaFun
	} from "@/utils/public.js";
	import {
		getHallPlanList,
		queryFocusRolePlan,
		saveAgree
	} from '@/api/plan.js';
	import {
		apiGodOrderList
	} from '@/api/home';

	export default {
		name: "Plan",
		components: {
			// HActionSheetA,
			// HDialogC
		},
		setup() {
			// const load = () =>{
			//
			// };
			const isApp = uaFun()
			const bangFun = () =>{
				oCPData.bang.isBang = false
				oCPData.bang.styleBang2 = 'font-size: 0.373rem;font-weight: 400'
				oCPData.bang.styleBang1 = ''
				getHallPlanListFun()
			};
			const bangFun1 = () =>{
				oCPData.bang.isBang = true
				oCPData.bang.styleBang1 = 'font-size: 0.373rem;font-weight: 400'
				oCPData.bang.styleBang2 = ''
				queryFocusRolePlanFun()
			};

			const router = useRouter(); //初始化路由
			const route = useRoute();
			const fGlobalReload = inject("fGlobalReload");
			const store = useStore(); // 使用useStore方法
			const UserInfo = store.state.userInfo.userInfo;


			const apiGodOrderListFun = () =>{
				apiGodOrderList({
					"locationEnum": "HALL"
				}).then(res=>{
					oCPData.godData = res.result
				})
			};
			const getHallPlanListFun = (lotteryTypeEnum) =>{
				Toast({
					type: "loading"
				});
				getHallPlanList({
					current:1,
					size:oCPData.size,
					roleId:UserInfo.roleId,
					lotteryTypeEnum:lotteryTypeEnum
				}).then(res=>{
					oCPData.planList = res.result.records
				})
			};
			const queryFocusRolePlanFun = (lotteryTypeEnum) =>{
				Toast({
					type: "loading"
				});
				queryFocusRolePlan({
					current:1,
					size:oCPData.size,
					userId:UserInfo.userId,
					roleId:UserInfo.roleId,
					lotteryTypeEnum:lotteryTypeEnum
				}).then(res=>{
					oCPData.planListGuan = res.result
				})
			};
			const dianzFun = (e,isZan) =>{
				Toast({
					type: "loading"
				});
				saveAgree({
					agreeSign:isZan,
					entityId:e.planId,
					gatherAgreeRoleId:e.roleId,
					gatherAgreeUserId:e.userId,
					roleId:UserInfo.roleId,
					type:"PLAN",
					userId:UserInfo.userId
				}).then(()=>{
					getHallPlanListFun()
				})
			};

			const listenBottomOut = () =>{

				//正文总高度
				// console.log(document.getElementById("scroll").scrollTop);
				let scrollHeight = document.getElementById("scroll").scrollHeight;
				//元素可见区域高度
				let offsetHeight = document.getElementById("scroll").offsetHeight;
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop = document.getElementById("scroll").scrollTop;
				//避免切换时读取到异常高度
				if (scrollTop == 0) {
					scrollHeight= 10000;
				}
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
				//console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

				// console.log("加载中~");
				if (scrollTop >= scrollHeight - offsetHeight) {
					//此处添加自定义操作
					oCPData.size += 20
					getHallPlanListFun()
				}


			};
			const listenBottomOut1 = () =>{

				//正文总高度
				// console.log(document.getElementById("scroll").scrollTop);
				let scrollHeight = document.getElementById("scroll1").scrollHeight;
				//元素可见区域高度
				let offsetHeight = document.getElementById("scroll1").offsetHeight;
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop = document.getElementById("scroll1").scrollTop;
				//避免切换时读取到异常高度
				if (scrollTop == 0) {
					scrollHeight= 10000;
				}
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
				//console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

				// console.log("加载中~");
				if (scrollTop >= scrollHeight - offsetHeight) {
					//此处添加自定义操作
					oCPData.size += 20
					queryFocusRolePlanFun()
				}


			};


			const oCPData = reactive({
				isTiShow:false,
				select:{
					lotteryTypeEnum: "",
					name: "全部"
				},
				option:[
					{
						lotteryTypeEnum: "",
						name: "全部"
					},
					{
						lotteryTypeEnum: "JCZQ",
						name: "竞彩足球"
					},

					{
						lotteryTypeEnum: "JCLQ",
						name: "竞彩篮球"
					}
				],
				show:false,
				oNCData: {},
				oYCData: {
					dialogCA: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					},
					dialogCB: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "确定"
					},
					dialogCC: {
						showDialog: false,
						showCancelButton: false,
						confirmButtonText: "去支付佣金"
					},
					dialogCD: {
						showDialog: false,
						showCancelButton: true,
						confirmButtonText: "确定"
					}
				},
				bang:{
					isBang:false,
					styleBang2:'font-size: 0.373rem;font-weight: 400',
					styleBang1:'',
				},
				godData:[],
				planList:[],
				planListGuan:[],
				size:20
			});





			// 前往方案详情
			const toDetailFun = (Id) => {
				// 跳转到方案详情
				router.push({
					path: '/planDetail',
					query: {
						id: Id
					}
				})
			}
			const toZhuye = (Id) =>{
				// 跳转到方案详情
				router.push({
					path: '/zhuye',
					query: {
						id: Id
					}
				})
			}


			// 生命周期
			onActivated(() => {
				const route_meta = route.meta;
				if (route_meta.resetType === '1') {
					route_meta.resetType = '0';
					fGlobalReload();
				} else if (route_meta.resetType === '0') {
					route_meta.resetType = '1';

				}
			});
			const fClosePlanStatus = () => {
				console.log("方案状态");
				oCPData.show = false;
			};
			const fOpenPlanStatus = () => {
				console.log("方案状态");
				oCPData.show = true;
				// fCloseSearch();
			};
			const fSelectPlanStatus = (Item) => {
				if (Item.name !== oCPData.select.name) {
					oCPData.select = Item;
					if(!oCPData.bang.isBang){
						getHallPlanListFun(Item.lotteryTypeEnum)
					}else {
						queryFocusRolePlanFun(Item.lotteryTypeEnum)
					}

				}
				oCPData.show = false;
			};
			const fGoPath = (path) => {
				router.push({
					path: '/' + path
				})
			};
			const tixin = () =>{
				sessionStorage.setItem('isTiShow', true);
				oCPData.isTiShow = JSON.parse(sessionStorage.getItem('isTiShow'));
			}
			oCPData.isTiShow = JSON.parse(sessionStorage.getItem('isTiShow'));
			apiGodOrderListFun()
			getHallPlanListFun()

			return {
				tixin,
				bangFun,
				fGoPath,
				fOpenPlanStatus,
				fClosePlanStatus,
				fSelectPlanStatus,
				bangFun1,
				queryFocusRolePlanFun,
				dianzFun,
				toDetailFun,
				toZhuye,
				listenBottomOut,
				listenBottomOut1,
				fnFormatData,
				fnPlanStatusCode,
				isApp,
				fnPlanStatusCodeColor,
				oCPData
			};
		}
	}
</script>

<style scoped lang="scss">
	.plan {
		width: 100%;
		height: 100%;
		background: #F1F2F7;
		.plan_box{
			background: #F1F2F7;
			padding-bottom: 1.64rem;
			.plan_top{
				/*background-image: url("~@/assets/images/new/new_012.png");*/
				/*padding-bottom: 0.854rem;*/
				/*box-sizing: border-box;*/
				margin-bottom: 0.8rem;
				.plan_top_box{
					width: 100%;
					height: 6.4rem;
					position: absolute;
					.plan_top_title_box{
						padding: 0 0.4rem;
						box-sizing: border-box;
						margin-bottom: 0.427rem;
						width: 100%;
						height: 1.173rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						div{
							font-size: 0.48rem;
							font-weight: 500;
							color: #383838;
						}
						img{
							width: 0.48rem;
							height: 0.48rem;
						}
					}
					.plan_top_bot{
						width: 100%;
						padding: 0 0.4rem;
						box-sizing: border-box;
						display: flex;
						flex-wrap: wrap;
						.plan_top_bot_item{
							width: 25%;
							display: flex;
							flex-direction: column;
							align-items: center;
							padding: 0.293rem 0 0.267rem;
							box-sizing: border-box;
						/*	img{*/
						/*		width: 1.28rem;*/
						/*		height: 1.28rem;*/
						/*		border-radius: 0.64rem;*/
						/*		margin-bottom: 0.107rem;*/
						/*	}*/
						/*	.plan_top_bot_font{*/
						/*		font-size: 0.32rem;*/
						/*		font-weight: 400;*/
						/*		color: #383838;*/
						/*		line-height: 0.453rem;*/
						/*		padding: 0 0.107rem ;*/
						/*		box-sizing: border-box;*/
						/*	}*/
							.dashen_item_box{
								width: 1.173rem;
								height: 1.173rem;
								margin: 0.24rem 0 0.107rem;
								.dashen_box{
									width: 1.173rem;
									height: 1.173rem;
									position: relative;
									img{
										width: 100%;
										height: 100%;
										border-radius: 0.587rem;
									}
									div{
										min-width: 0.427rem;
										height: 0.427rem;
										background: #FF7671;
										border-radius: 0.213rem;
										font-size: 0.32rem;
										font-weight: 500;
										line-height: 0.427rem;
										color: #FFFFFF;
										position: absolute;
										top: -0.107rem;
										right: -0.107rem;
										text-align: center;
										padding: 0 0.107rem;
										box-sizing: border-box;
									}
								}
							}
							.dashen_item_title{
								width: 100%;
								font-size: 0.32rem;
								font-weight: 400;
								color: #383838;
								line-height: 0.453rem;
								text-align: center;
								margin-bottom: 0.24rem;
							}
						}

					}
				}

				.plan_tou_back{
					width: 100%;
					height: 6.4rem;
				}
			}
			.gendan{
				width: calc(100% - 0.64rem);
				height: 16.24rem;
				margin: 0 auto;
				background: #FAFBFF;
				box-shadow: 0px 0.053rem 0.427rem 0px rgba(224,228,246,0.5);
				border-radius: 0.32rem;
				.bang_top{
					width: 100%;
					height: 1.147rem;
					display: flex;
					justify-content:space-between;
					align-items: flex-end;
					margin-bottom: 0.4rem;
					.you{
						position: relative;
						.HDropdownMenu_show {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 0.2133rem 0.5066rem;

							min-width: 2.5866rem;
							box-sizing: border-box;

							&>span {
								font-size: 0.32rem;
								font-weight: 500;
								color: #383838;
								line-height: 0.4533rem;
							}

							&>img {
								margin-left: 0.1066rem;
								width: 0.1866rem;
								height: auto;
							}
						}

						.HDropdownMenu_show--select {
							&>span {
								color: #FF7671;
							}

							&>img {
								margin-left: 0.1066rem;
								width: 0.1866rem;
								height: auto;
							}
						}

						.HDropdownMenu_content {
							position: absolute;
							left: 0;
							top: 0;
							z-index: 30;

							background: #FFFFFF;
							box-shadow: 0px 0.0533rem 0.2666rem 0px rgba(224, 228, 246, 0.8);
							border-radius: 0.2133rem;

							.HDropdownMenu_content_title {
								display: flex;
								align-items: center;
								padding: 0.2133rem 0.5066rem;

								&>span {
									font-size: 0.32rem;
									font-weight: 500;
									color: #FF7671;
									line-height: 0.4533rem;
								}

								&>img {
									margin-left: 0.1066rem;
									width: 0.1866rem;
									height: auto;
								}
							}

							.HDropdownMenu_content_option {
								margin: 0.2666rem 0.48rem;
								padding: 0.0266rem 0;
								text-align: center;

								font-size: 0.32rem;
								font-weight: 500;
								color: #383838;
								line-height: 0.4533rem;
							}

							.HDropdownMenu_content_option--selected {
								color: #FF7671;
							}
						}
						.HDropdownMenu_content_option {
							margin: 0.2666rem 0.48rem;
							padding: 0.0266rem 0;
							text-align: center;

							font-size: 0.32rem;
							font-weight: 500;
							color: #383838;
							line-height: 0.4533rem;
						}

						.HDropdownMenu_content_option--selected {
							color: #FF7671;
						}
					}

					.zhog{
						height: 1.147rem;
						display: flex;
						justify-content:center;
						align-items: flex-end;
						.bang_title_box{
							/*width: 1.707rem;*/
							/*height: 100%;*/
							.bang_title{
								/*width: 1.707rem;*/
								height: 0.587rem;
								font-size: 0.427rem;
								font-weight: 600;
								color: #383838;
								line-height: 0.587rem;
								position: relative;
								img{
									width: 0.507rem;
									height: 0.16rem;
									position: absolute;
									left: calc((100% - 0.507rem)/2);
									bottom: -0.187rem;
								}
							}

						}
					}

				}
				.gendan_hua{
					height: 14.692rem;
					overflow-y: auto;
					padding-top: 0.3rem;
					box-sizing: border-box;
					.wsj_xob{
						width: 100%;
						height: 100%;
						img{
							width: calc(100% - 2.72rem);
							height: auto;
							margin:0 1.36rem ;
						}
						div{
							width: 100%;
							height: 0.453rem;
							font-size: 0.32rem;
							font-weight: 400;
							color: #979797;
							line-height: 0.453rem;
							text-align: center;
						}
					}
					.gendan_item{
						width: calc(100% - 0.8rem);
						margin: 0 auto 0.533rem;
						.gendan_item_1{
							padding: 0 20px;
							box-sizing: border-box;
							width: 100%;
							background: #FFFFFF;
							box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
							border-radius: 0.213rem;
							.gen_item_title{
								width: 1.76rem;
								height: 0.533rem;
								background: #053FC5;
								border-radius: 0.053rem 0.213rem 0.053rem 0.213rem;
								font-size: 0.373rem;
								font-weight: 400;
								color: #DEF7FF;
								text-align: center;
								line-height: 0.533rem;
								float: right;
								margin-top: -0.107rem;
							}
							.gen_item_info{
								width: 100%;
								height: 1.28rem;
								display: flex;
								justify-content: space-between;
								.gen_item_info_left{
									display: flex;
									.info_1{
										width: 1.28rem;
										height: 1.28rem;
										.gen_item_img{
											width: 100%;
											height: 100%;
											position: relative;
											.img_box_1{
												width: 100%;
												height: 100%;
												border-radius: 0.64rem;
											}
											.img_box_2{
												width: 0.427rem;
												height: 0.56rem;
												position: absolute;
												left: 0;
												bottom: 0;
											}
											.img_box_font{
												padding: 0 0.107rem;
												box-sizing: border-box;
												height: 0.427rem;
												background: #FF7671;
												border-radius: 0.213rem;
												line-height: 0.427rem;
												text-align: center;
												font-size: 0.32rem;
												font-weight: 500;
												color: #FFFFFF;
												position: absolute;
												top: 0;
												right: 0;
											}
										}
									}
									.info_2{
										height: 100%;
										margin-left: 0.32rem;
										.info_2_top{
											display: flex;
											margin-bottom:0.107rem;
											.info_2_top_name{
												font-size: 0.373rem;
												font-weight: 400;
												color: #383838;
												margin-right: 0.107rem;
												line-height:0.967rem ;
												height: 0.667rem;
											}
											.info_2_top_lian{
												width: 1.467rem;
												height: 0.667rem;
												.info_2_top_lian1{
													width: 100%;
													height: 100%;
													position: relative;
													.info_2_top_lian2{
														width: 100%;
														height: 100%;
														position: absolute;
														left: 0;
														top: 0;
													}
													.info_2_top_lian3{
														width: 100%;
														height: 100%;
														position: absolute;
														left: 0;
														top: 0;
														text-align: center;
														.info_2_top_lian4{
															font-size: 0.32rem;
															font-weight: bold;
															color: #E2335D;
															margin-right: 0.107rem;
															line-height:0.967rem ;
														}
														.info_2_top_lian5{
															font-size: 0.213rem;
															font-weight: 500;
															color: #383838;
															line-height:0.967rem ;
														}
													}
												}
											}

										}
										.info_2_bot{
											font-size: 0.267rem;
											font-weight: 400;
											color: #979797;
										}
									}
								}

								.info_3{
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: flex-end;
									.info_3_1{
										font-size: 0.533rem;
										font-weight: bold;
										color: #E2335D;
										margin-bottom: 0.107rem;
									}
									.info_3_2{
										font-size: 0.32rem;
										font-weight: 400;
										color: #383838;
									}
								}
							}
							.gen_liuyan{
								width: 100%;
								height: 1.493rem;
								.gen_liuyan_box{
									position: relative;
									width: 100%;
									height: 1.493rem;
									img{
										width: 100%;
										height: 100%;
										position: absolute;
										top: 0;
										left: 0;
									}
									.liuyan_font{
										/*padding: 0.373rem 0.4rem 0.213rem;*/
										/*box-sizing: border-box;*/
										width: calc(100% - 0.8rem);
										height: calc(100% - 0.586rem);
										overflow:hidden;  //超出文本隐藏

										text-overflow:ellipsis;  ///超出部分省略号显示

										display:-webkit-box;  //弹性盒模型

										-webkit-box-orient:vertical;  //上下垂直

										-webkit-line-clamp:2;  //自定义行数
										position: absolute;
										top: 0.373rem;
										left: 0.4rem;
										font-size: 0.32rem;
										font-weight: 400;
										color: #383838;
										line-height: 0.453rem;

									}
								}


							}
							.gen_chuan{
								width: 100%;
								height: 1.573rem;
								display: flex;
								align-items: center;
								.gen_chuan_item{
									width: calc(25% - 0.08rem);
									height: 100%;
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
									margin-bottom: 0.053rem;
									.gen_chuan_item_top{
										height: 0.453rem;
										background: #FF7671;
										border-radius: 0.107rem;
										line-height: 0.453rem;
										text-align: center;
										padding: 0 0.4rem;
										box-sizing: border-box;
										font-size: 0.32rem;
										font-weight: 400;
										color: #FFFFFF;
										margin-bottom: 0.107rem;
									}
									.chuan_item_bot{
										font-size: 0.267rem;
										font-weight: 400;
										color: #383838;
									}
									.chuan_item_top{
										font-size: 0.4rem;
										font-weight: bold;
										color: #E2335D;
										margin-bottom: 0.107rem;
									}
								}
								.gen_chuan_item_shu{
									width: 0.027rem;
									height: 0.56rem;
									background: #EAEAEA;
								}
							}
						}
						.gendan_item_2{
							width: calc(100% - 1.547rem);
							margin: 0 auto;
							height: 0.853rem;
							background: #FFFFFF;
							border-radius: 0px 0px 0.213rem 0.213rem;
							border: 0.027rem solid #F4F6FF;
							padding: 0 0.747rem;
							box-sizing: border-box;
							display: flex;
							justify-content: space-between;
							align-items: center;
							.gendan_item_2_item{
								display: flex;
								align-items: center;
								img{
									width: 0.48rem;
									height: 0.48rem;
									margin-right: 0.107rem;
								}
								div{
									font-size: 0.373rem;
									font-weight: 400;
									color: #B4B4B4;
								}
							}
						}

					}
					.bang_item{
						width: calc(100% - 0.8rem);
						height: 1.52rem;
						background: #FFFFFF;
						box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
						border-radius: 0.213rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin: 0.4rem auto 0.32rem;
						.bang_item_left_box{
							display: flex;
							/*justify-content: space-between;*/
							align-items: center;
							.bang_item_left{
								display: flex;
								align-items: center;
								margin-right: 0.133rem;
								.bang_item_left_tou{
									width: 1.013rem;
									height: 1.013rem;
									margin: 0 0.293rem 0 0.4rem;
									.tou{
										width: 1.013rem;
										height: 1.013rem;
										position: relative;
										img{
											width: 1.013rem;
											height: 1.013rem;
											border-radius: 0.507rem;
										}
										div{
											padding: 0 0.107rem;
											box-sizing: border-box;
											height: 0.427rem;
											background: #FF7671;
											border-radius: 0.213rem;
											line-height: 0.427rem;
											text-align: center;
											position: absolute;
											top: 0;
											right: 0;
											font-size: 0.32rem;
											font-weight: 500;
											color: #FFFFFF;
										}
									}
								}
							}
							.bang_item_right1{
								/*width: 1.067rem;*/
								height: 100%;
								/*padding-top: 0.32rem;*/
								/*box-sizing: border-box;*/
								margin-right: 0.267rem;
								div{
									height: 0.667rem;
									position: relative;
									img{
										width: 1.067rem;
										height: 0.667rem;
									}
									.font_box{
										width: 100%;
										height: 0.667rem;
										position: absolute;
										top: 0;
										right: 0;
										line-height: 0.973rem;
										text-align: center;
										span{
											font-size: 0.213rem;
											font-weight: 500;
											color: #383838;
										}
									}
								}

								/*height: 0.667rem;*/
							}
							.bang_item_right{
								display: flex;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-end;
								margin-right: 0.267rem;
								.bang_item_right_r{
									display: flex;
									align-items: flex-end;
									img{
										width: 0.8rem;
										height: 0.8rem;
										margin-right: 0.213rem;
									}
									.bang_item_right_title{
										font-size: 0.32rem;
										font-weight: 600;
										color: #383838;
										line-height: 0.453rem;
									}
								}

								.bang_item_right_je{
									font-size: 0.373rem;
									font-weight: bold;
									color: #E2335D;
									line-height: 0.427rem;
								}
							}
						}
						.bang_item_fon{
							font-size: 0.32rem;
							font-weight: 400;
							color: #979797;
							margin-right: 0.4rem;
						}

					}
				}


			}

		}

		.plan_tab_content {
			height: 100%;
			overflow-x: auto;
			padding: 0.32rem 0.32rem 1.36rem;
			.plan_list {
				padding: 0.24rem 0.32rem 0.4533rem 0.4rem;
				background: #FFFFFF;
				box-shadow: 0px 0.0533rem 0.2133rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.2133rem;
				margin-bottom: 0.32rem;

				display: flex;
				flex-direction: column;
				min-height: 2.48rem;
				box-sizing: border-box;

				.plan_list_a {
					display: flex;
					align-items: center;
					margin-bottom: 0.32rem;

					.plan_list_aa {
						display: flex;
						align-items: center;

						.plan_list_aaaa {
							display: inline-block;
							width: 0.1066rem;
							height: 0.48rem;
							background: #033EC2;
							border-radius: 0.0533rem;
						}

						.plan_list_aaab {
							display: inline-block;
							width: 0.1066rem;
							height: 0.48rem;
							background: #F21B8E;
							border-radius: 0.0533rem;
						}

						.plan_list_aaac {
							display: inline-block;
							width: 0.1066rem;
							height: 0.48rem;
							background: #AA1AE1;
							border-radius: 0.0533rem;
						}

						.plan_list_aab {
							margin-left: 0.1066rem;
							font-size: 0.3733rem;
							font-weight: 600;
							color: #383838;
						}

						.plan_list_aaca {
							padding: 0.08rem;
							margin-left: 0.1066rem;

							background: #E2335D;
							border-radius: 0.1066rem;

							font-size: 0.32rem;
							font-weight: 500;
							color: #FFFFFF;
						}

						.plan_list_aacb {
							padding: 0.08rem;
							margin-left: 0.1066rem;

							background: #FED530;
							border-radius: 0.1066rem;

							font-size: 0.32rem;
							font-weight: 500;
							color: #383838;
						}
					}

					.plan_list_ab {
						margin-left: 0.4266rem;

						font-size: 0.32rem;
						font-weight: 400;
						color: #979797;
					}
				}

				.plan_list_b {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.plan_list_ba {
						&>div {
							display: flex;
							align-items: center;
						}

						.plan_list_baa {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;
							margin-bottom: 0.0533rem;

							&>em {
								font-size: 0.3733rem;
								font-weight: bold;
								color: #383838;
							}
						}

						.plan_list_bab {
							font-size: 0.32rem;
							font-weight: 400;
							color: #383838;

							&>em {
								font-size: 0.3733rem;
								font-weight: bold;
								color: #E2335D;
							}
						}
					}

					.plan_list_bb {
						padding: 0.16rem 0.32rem;

						background: linear-gradient(180deg, #FF7671 0%, #E2335D 100%);
						border-radius: 0.4266rem;

						font-size: 0.32rem;
						font-weight: 500;
						color: #FFFFFF;
					}

					.plan_list_bc {
						margin-bottom: -0.8rem;
						font-size: 0.32rem;
						font-weight: 500;
					}

					.plan_list_bd {
						margin-bottom: -0.8rem;
						font-size: 0.32rem;
						font-weight: 500;
					}

					.plan_list_be {
						margin-bottom: -0.8rem;
						font-size: 0.32rem;
						font-weight: 500;
					}

					.plan_list_bi {
						width: 1.5733rem;
						height: auto;
						margin-bottom: 0;
					}
				}
			}
		}

		.plan_tips {
			padding: 0.2666rem 0.64rem 0.88rem;
			font-size: 0.4266rem;
			font-weight: 400;
			color: #383838;
			line-height: 0.5866rem;
		}

		.plan_dialogCD {
			text-align: center;
		}
	}
	.tix{
		width: 7.56rem;
		height: 1.76rem;
		position: fixed;
		left: calc((100% - 7.56rem)/2);
		bottom: 1.467rem;
		z-index: 99999;
	}
	.tix1{
		width: 0.267rem;
		height: 0.267rem;
		position: fixed;
		right:calc(((100% - 7.56rem)/2) + 0.633rem);
		bottom: 2.241rem;
		z-index: 999999;
	}
</style>
