<template>
    <div class="searchfor" :style="isApp ? 'padding-top: 1.913rem' : ''">
        <div class="search_top">
            <img src="~@/assets/images/new/new_010.png" alt="" class="zuo" @click="fLeft">
            <div class="search_box">
                <img src="~@/assets/images/new/new_001.png" alt="">
                <input type="text" id="search" placeholder="请输入要搜索的大神名称" v-model="oCPData.name" @input="changFun">
            </div>
            <div class="search_title" @click="getDetailByNameFun">搜索</div>
        </div>
        <div class="gendan_hua"  @scroll="listenBottomOut" id="scroll" v-if="oCPData.isName">
            <!--					v-infinite-scroll="load" style="overflow: auto"-->
            <div class="gendan_item" v-for="(item,index) in oCPData.planList" :key="index">
                <div class="gendan_item_1" @click="toDetailFun(item.planId)">
                    <div class="gen_item_title" v-if="item.lotteryType == 'JCZQ'">
                        竞彩足球
                    </div>
                    <div class="gen_item_title" v-if="item.lotteryType == 'JCLQ'">
                        竞彩篮球
                    </div>
                    <div class="gen_item_title" v-if="item.lotteryType == 'BJDC'">
                        北京单场
                    </div>
                    <div class="gen_item_info">
                        <div class="gen_item_info_left" >
                            <div class="info_1">
                                <div class="gen_item_img">
                                    <img :src="item.portraitUrl" alt="" class="img_box_1" v-if="item.portraitUrl">
                                    <img src="~@/assets/images/new/new_019.png" alt="" v-else>
                                    <img src="~@/assets/images/new/new_018.png" alt="" class="img_box_2" v-if="item.hasRedpack">
                                    <div class="img_box_font" v-if="item.evenRedCount > 0">{{item.evenRedCount}}</div>
                                </div>
                            </div>
                            <div class="info_2">
                                <div class="info_2_top">
                                    <div class="info_2_top_name">{{item.roleName}}</div>
                                    <div class="info_2_top_lian">
                                        <div class="info_2_top_lian1" v-if="item.evenRedCount > 0">
                                            <img src="~@/assets/images/new/new_005.png" alt="" class="info_2_top_lian2">
                                            <div class="info_2_top_lian3">
                                                <span class="info_2_top_lian4">{{item.evenRedCount}}</span> <span class="info_2_top_lian5">连胜</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info_2_bot">
                                    截止时间：{{fnFormatData(item.stopCopy,"MM-dd hh:mm")}}
                                </div>
                            </div>
                        </div>

                        <div class="info_3">
                            <div class="info_3_1">
                                ¥{{item.totalAmount}}
                            </div>
                            <div class="info_3_2">
                                自购金额
                            </div>
                        </div>

                    </div>

                    <div class="gen_liuyan">
                        <div class="gen_liuyan_box">
                            <img src="~@/assets/images/new/new_014.png" alt="">
                            <div class="liuyan_font">
                                {{item.planName}}
                            </div>
                        </div>

                    </div>

                    <div class="gen_chuan">
                        <div class="gen_chuan_item">
                            <div class="gen_chuan_item_top">
                                {{item.mixTypes}}
                            </div>
                            <div class="chuan_item_bot">
                                串联方式
                            </div>
                        </div>
                        <div class="gen_chuan_item_shu"></div>
                        <div class="gen_chuan_item">
                            <div class="chuan_item_top">
                                {{item.heat}}
                            </div>
                            <div class="chuan_item_bot">
                                热度
                            </div>
                        </div>
                        <div class="gen_chuan_item_shu"></div>
                        <div class="gen_chuan_item">
                            <div class="chuan_item_top">
                                {{item.profitProtectRate}}
                            </div>
                            <div class="chuan_item_bot">
                                保障赔率
                            </div>
                        </div>
                        <div class="gen_chuan_item_shu"></div>
                        <div class="gen_chuan_item">
                            <div class="chuan_item_top">
                                {{item.rate}}%
                            </div>
                            <div class="chuan_item_bot">
                                近7天回报率
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gendan_item_2">
                    <div class="gendan_item_2_item" @click="toDetailFun(item.planId)">
                        <img src="~@/assets/images/new/new_015.png" alt="">
                        <div>{{item.commentNum}}</div>
                    </div>
                    <div class="gendan_item_2_item" >
                        <img src="~@/assets/images/new/new_016.png" alt="" v-if="item.yesNo" @click="dianzFun(item,false)">
                        <img src="~@/assets/images/new/ic_02.png" alt="" v-else @click="dianzFun(item,true)">
                        <div>{{item.agreeNum}}</div>
                    </div>
                    <div class="gendan_item_2_item">
                        <img src="~@/assets/images/new/new_017.png" alt="">
                        <div>{{item.forwardNum}}</div>
                    </div>
                </div>
            </div>
            <div class="wsj_xob" v-if="oCPData.planList.length == 0">
                <img src="~@/assets/images/img_021.png" alt="" >
                <div>暂无数据</div>
            </div>
        </div>
        <template  v-else>
            <div class="search_ts">
                <div>
                    最近搜索
                </div>
                <img src="~@/assets/images/new/new_011.png" alt="" @click="deleLs">
            </div>
            <div class="search_history">
                <div class="search_history_item" v-for="(item,index) in oCPData.lsName" :key="index" @click="dianFun(item)">
                    {{item}}
                </div>
            </div>
            <div class="wsj_xob" >
                <img src="~@/assets/images/img_021.png" alt="" >
                <div>暂无数据</div>
            </div>
        </template>

    </div>
</template>

<script>
    import {
        getHallPlanList,
        saveAgree
    } from '@/api/plan.js';
    import {Toast} from "vant";
    import {
        fnFormatData,
        uaFun
    } from "@/utils/public.js";
    import {
        fnIsHasHistory
    } from "@/utils/router.js";
    import {
        reactive,
        onMounted,
        // onUnmounted
    } from "vue";
    import {useStore} from "vuex";
    import {useRouter} from "vue-router";
    export default {
        name: "searchPlan",
        setup(){
            const isApp = uaFun();
            const store = useStore(); // 使用useStore方法
            const UserInfo = store.state.userInfo.userInfo;
            const router = useRouter(); //初始化路由
            // fLeft van-nav-bar 左侧点击事件
            const oCPData = reactive({
                name:'',
                isName:false,
                planList:undefined,
                lsName:[],
                isStorage:true,
                size:20
            });
            const fLeft = () => {
                fnIsHasHistory("/");
                oCPData.name = undefined
                oCPData.planList = undefined
            };
            const dianFun = (e) =>{
                oCPData.name = e
                getDetailByNameFun()
            }
            const getDetailByNameFun = () =>{
                Toast({
                    type: "loading"
                });
                getHallPlanList({
                    current:1,
                    size:oCPData.size,
                    roleId:UserInfo.roleId,
                    key:oCPData.name
                }).then(res=>{
                    oCPData.planList = res.result.records
                    oCPData.isName = true
                    let isChong = true
                    if(oCPData.lsName.length > 0){
                        oCPData.lsName.forEach(e=>{
                            if(e == oCPData.name){
                                isChong = false
                            }
                        })
                    }
                    if(isChong){
                        if(oCPData.isStorage){
                            oCPData.lsName.push(oCPData.name)
                            localStorage.setItem('lsKe',  JSON.stringify(oCPData.lsName) )
                        }

                    }



                })
            };
            const changFun = () =>{
                if(oCPData.name.length == 0) {
                    oCPData.isName = false
                }
            };
            const listenBottomOut = () =>{

                //正文总高度
                // console.log(document.getElementById("scroll").scrollTop);
                let scrollHeight = document.getElementById("scroll").scrollHeight;
                //元素可见区域高度
                let offsetHeight = document.getElementById("scroll").offsetHeight;
                //可滚动容器超出当前窗口显示范围的高度
                let scrollTop = document.getElementById("scroll").scrollTop;
                //避免切换时读取到异常高度
                if (scrollTop == 0) {
                    scrollHeight= 10000;
                }
                //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
                //console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

                // console.log("加载中~");
                if (scrollTop >= scrollHeight - offsetHeight) {
                    //此处添加自定义操作
                    oCPData.size += 20
                    getDetailByNameFun()
                }


            };
            //删除历史记录
            const deleLs = () =>{
                if(oCPData.isStorage){
                    localStorage.setItem('lsKe',  '' )
                    oCPData.lsName = []
                }

            };
            // 页面卸载
            onMounted(() => {
                // localStorage.clear()
                if (window.Storage && window.localStorage && window.localStorage instanceof Storage) {
                    oCPData.isStorage = true
                } else {
                    oCPData.isStorage = false
                }
                if(oCPData.isStorage){
                    oCPData.lsName = localStorage.getItem('lsKe') ? JSON.parse(localStorage.getItem('lsKe'))  : []
                    console.log(oCPData.lsName,'页面加载');
                }

            });
            // 前往方案详情
            const toDetailFun = (Id) => {
                // 跳转到方案详情
                router.push({
                    path: '/planDetail',
                    query: {
                        id: Id
                    }
                })
            }
            const dianzFun = (e,isZan) =>{
                Toast({
                    type: "loading"
                });
                saveAgree({
                    agreeSign:isZan,
                    entityId:e.planId,
                    gatherAgreeRoleId:e.roleId,
                    gatherAgreeUserId:e.userId,
                    roleId:UserInfo.roleId,
                    type:"PLAN",
                    userId:UserInfo.userId
                }).then(()=>{
                    getDetailByNameFun()
                })
            };
            return{
                fLeft,
                oCPData,
                toDetailFun,
                getDetailByNameFun,
                dianFun,
                listenBottomOut,
                fnFormatData,
                dianzFun,
                deleLs,
                isApp,
                changFun
            }
        }
    }
</script>

<style scoped lang="scss">
    .searchfor{
        padding-top: 0.613rem;
        box-sizing: border-box;
        height: 100%;
        .search_top{
            width: 100%;
            height: 0.907rem;
            display: flex;
            justify-content: space-between;
            padding: 0 0.4rem ;
            box-sizing: border-box;

            align-items: center;
            .zuo{
                width: 0.213rem;
                height: 0.373rem;
            }
            .search_box{
                width: calc(100% - 2.667rem);
                height: 100%;
                border-radius: 0.453rem;
                border: 0.027rem solid #FF7671;
                display: flex;
                align-items: center;
                padding: 0 0.267rem;
                box-sizing: border-box;
                img{
                    width: 0.4rem;
                    height: 0.373rem;
                    margin-right: 0.24rem;
                }
                input::-webkit-input-placeholder {
                    color: #D2D2D2;
                }

                input::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: #D2D2D2;
                }

                input:-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    color: #D2D2D2;
                }

                input:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #D2D2D2;
                }
                #search{
                    height: 0.587rem;
                    font-size: 0.427rem;
                    font-weight: 400;
                    color: #FF7671;
                    line-height: 0.587rem;
                    caret-color:#FF7671;
                }
            }
            .search_title{
                font-size: 0.427rem;
                font-weight: 400;
                color: #E2335D;
            }
        }
        .search_ts{
            width: 100%;
            height: 0.587rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.4rem ;
            box-sizing: border-box;
            margin-bottom: 0.213rem;
            margin-top: 0.773rem;
            div{
                font-size: 0.427rem;
                font-weight: 400;
                color: #383838;
            }
            img{
                width: 0.533rem;
                height: 0.533rem;
            }
        }
        .search_history{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 0 0.4rem ;
            box-sizing: border-box;
            .search_history_item{
                padding: 0 0.24rem;
                box-sizing: border-box;
                height: 0.533rem;
                background: #EAEAEA;
                border-radius: 0.267rem;
                font-size: 0.32rem;
                font-weight: 400;
                color: #979797;
                line-height: 0.533rem;
                text-align: center;
                margin-right: 0.32rem;
                margin-bottom: 0.213rem;
            }
        }
        .search_jg{
            width: calc(100% - 1.44rem);
            height: 1.493rem;
            margin: 0.453rem auto 0;
            display: flex;
            align-items: center;
            border-bottom: 0.013rem solid #F5F5F5;
            img{
                width: 1.013rem;
                height: 1.013rem;
                margin-right: 0.32rem;
                border-radius: 0.507rem;
            }
            div{
                font-size: 0.373rem;
                font-weight: 400;
                color: #383838;
            }
        }
    }
    .wsj_xob{
        width: 100%;
        /*height: 100%;*/
        padding-top: 2.667rem;
        box-sizing: border-box;

        img{
            width: calc(100% - 2.72rem);
            height: auto;
            margin:0 1.36rem ;
        }
        div{
            width: 100%;
            height: 0.453rem;
            font-size: 0.32rem;
            font-weight: 400;
            color: #979797;
            line-height: 0.453rem;
            text-align: center;
        }
    }
    .gendan_hua{
        height:calc(100% - 0.907rem);
        overflow-y: auto;
        padding: 0.64rem 0;
        box-sizing: border-box;

        .wsj_xob{
            width: 100%;
            height: 100%;
            padding-top: 2.667rem;
            box-sizing: border-box;
            img{
                width: calc(100% - 2.72rem);
                height: auto;
                margin:0 1.36rem ;
            }
            div{
                width: 100%;
                height: 0.453rem;
                font-size: 0.32rem;
                font-weight: 400;
                color: #979797;
                line-height: 0.453rem;
                text-align: center;
            }
        }

        .gendan_item{
            width: calc(100% - 0.8rem);
            margin: 0 auto 0.533rem;
            .gendan_item_1{
                padding: 0 20px;
                box-sizing: border-box;
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
                border-radius: 0.213rem;
                .gen_item_title{
                    width: 1.76rem;
                    height: 0.533rem;
                    background: #053FC5;
                    border-radius: 0.053rem 0.213rem 0.053rem 0.213rem;
                    font-size: 0.373rem;
                    font-weight: 400;
                    color: #DEF7FF;
                    text-align: center;
                    line-height: 0.533rem;
                    float: right;
                    margin-top: -0.107rem;
                }
                .gen_item_info{
                    width: 100%;
                    height: 1.28rem;
                    display: flex;
                    justify-content: space-between;
                    .gen_item_info_left{
                        display: flex;
                        .info_1{
                            width: 1.28rem;
                            height: 1.28rem;
                            .gen_item_img{
                                width: 100%;
                                height: 100%;
                                position: relative;
                                .img_box_1{
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 0.64rem;
                                }
                                .img_box_2{
                                    width: 0.427rem;
                                    height: 0.56rem;
                                    position: absolute;
                                    left: 0;
                                    bottom: 0;
                                }
                                .img_box_font{
                                    padding: 0 0.107rem;
                                    box-sizing: border-box;
                                    height: 0.427rem;
                                    background: #FF7671;
                                    border-radius: 0.213rem;
                                    line-height: 0.427rem;
                                    text-align: center;
                                    font-size: 0.32rem;
                                    font-weight: 500;
                                    color: #FFFFFF;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }
                        .info_2{
                            height: 100%;
                            margin-left: 0.32rem;
                            .info_2_top{
                                display: flex;
                                margin-bottom:0.107rem;
                                .info_2_top_name{
                                    font-size: 0.373rem;
                                    font-weight: 400;
                                    color: #383838;
                                    margin-right: 0.107rem;
                                    line-height:0.967rem ;
                                    height: 0.667rem;
                                }
                                .info_2_top_lian{
                                    width: 1.467rem;
                                    height: 0.667rem;
                                    .info_2_top_lian1{
                                        width: 100%;
                                        height: 100%;
                                        position: relative;
                                        .info_2_top_lian2{
                                            width: 100%;
                                            height: 100%;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                        }
                                        .info_2_top_lian3{
                                            width: 100%;
                                            height: 100%;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            text-align: center;
                                            .info_2_top_lian4{
                                                font-size: 0.32rem;
                                                font-weight: bold;
                                                color: #E2335D;
                                                margin-right: 0.107rem;
                                                line-height:0.967rem ;
                                            }
                                            .info_2_top_lian5{
                                                font-size: 0.213rem;
                                                font-weight: 500;
                                                color: #383838;
                                                line-height:0.967rem ;
                                            }
                                        }
                                    }
                                }

                            }
                            .info_2_bot{
                                font-size: 0.267rem;
                                font-weight: 400;
                                color: #979797;
                            }
                        }
                    }

                    .info_3{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        .info_3_1{
                            font-size: 0.533rem;
                            font-weight: bold;
                            color: #E2335D;
                            margin-bottom: 0.107rem;
                        }
                        .info_3_2{
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #383838;
                        }
                    }
                }
                .gen_liuyan{
                    width: 100%;
                    height: 1.493rem;
                    .gen_liuyan_box{
                        position: relative;
                        width: 100%;
                        height: 1.493rem;
                        img{
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        .liuyan_font{
                            /*padding: 0.373rem 0.4rem 0.213rem;*/
                            /*box-sizing: border-box;*/
                            width: calc(100% - 0.8rem);
                            height: calc(100% - 0.586rem);
                            overflow:hidden;  //超出文本隐藏

                            text-overflow:ellipsis;  ///超出部分省略号显示

                            display:-webkit-box;  //弹性盒模型

                            -webkit-box-orient:vertical;  //上下垂直

                            -webkit-line-clamp:2;  //自定义行数
                            position: absolute;
                            top: 0.373rem;
                            left: 0.4rem;
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #383838;
                            line-height: 0.453rem;

                        }
                    }


                }
                .gen_chuan{
                    width: 100%;
                    height: 1.573rem;
                    display: flex;
                    align-items: center;

                    .gen_chuan_item{
                        width: calc(25% - 0.08rem);
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0.053rem;
                        .gen_chuan_item_top{
                            height: 0.453rem;
                            background: #FF7671;
                            border-radius: 0.107rem;
                            line-height: 0.453rem;
                            text-align: center;
                            padding: 0 0.4rem;
                            box-sizing: border-box;
                            font-size: 0.32rem;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-bottom: 0.107rem;
                        }
                        .chuan_item_bot{
                            font-size: 0.267rem;
                            font-weight: 400;
                            color: #383838;
                        }
                        .chuan_item_top{
                            font-size: 0.4rem;
                            font-weight: bold;
                            color: #E2335D;
                            margin-bottom: 0.107rem;
                        }
                    }
                    .gen_chuan_item_shu{
                        width: 0.027rem;
                        height: 0.56rem;
                        background: #EAEAEA;
                    }
                }
            }
            .gendan_item_2{
                width: calc(100% - 1.547rem);
                margin: 0 auto;
                height: 0.853rem;
                background: #FFFFFF;
                border-radius: 0px 0px 0.213rem 0.213rem;
                border: 0.027rem solid #F4F6FF;
                padding: 0 0.747rem;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .gendan_item_2_item{
                    display: flex;
                    align-items: center;
                    img{
                        width: 0.48rem;
                        height: 0.48rem;
                        margin-right: 0.107rem;
                    }
                    div{
                        font-size: 0.373rem;
                        font-weight: 400;
                        color: #B4B4B4;
                    }
                }
            }

        }
        .bang_item{
            width: calc(100% - 0.8rem);
            height: 1.52rem;
            background: #FFFFFF;
            box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
            border-radius: 0.213rem;
            display: flex;
            /*justify-content: space-between;*/
            align-items: center;
            margin: 0.4rem auto 0.32rem;
            .bang_item_left{
                display: flex;
                align-items: center;
                margin-right: 0.133rem;
                .bang_item_left_tou{
                    width: 1.013rem;
                    height: 1.013rem;
                    margin: 0 0.293rem 0 0.4rem;
                    .tou{
                        width: 1.013rem;
                        height: 1.013rem;
                        position: relative;
                        img{
                            width: 1.013rem;
                            height: 1.013rem;
                            border-radius: 0.507rem;
                        }
                        div{
                            padding: 0 0.107rem;
                            box-sizing: border-box;
                            height: 0.427rem;
                            background: #FF7671;
                            border-radius: 0.213rem;
                            line-height: 0.427rem;
                            text-align: center;
                            position: absolute;
                            top: 0;
                            right: 0;
                            font-size: 0.32rem;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }
                }
            }
            .bang_item_right1{
                /*width: 1.067rem;*/
                height: 100%;
                padding-top: 0.32rem;
                box-sizing: border-box;
                margin-right: 0.267rem;
                div{
                    height: 0.667rem;
                    position: relative;
                    img{
                        width: 1.067rem;
                        height: 0.667rem;
                    }
                    .font_box{
                        width: 100%;
                        height: 0.667rem;
                        position: absolute;
                        top: 0;
                        right: 0;
                        line-height: 0.973rem;
                        text-align: center;
                        span{
                            font-size: 0.213rem;
                            font-weight: 500;
                            color: #383838;
                        }
                    }
                }

                /*height: 0.667rem;*/
            }
            .bang_item_right{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                margin-right: 0.267rem;
                .bang_item_right_r{
                    display: flex;
                    align-items: flex-end;
                    img{
                        width: 0.8rem;
                        height: 0.8rem;
                        margin-right: 0.213rem;
                    }
                    .bang_item_right_title{
                        font-size: 0.32rem;
                        font-weight: 600;
                        color: #383838;
                        line-height: 0.453rem;
                    }
                }

                .bang_item_right_je{
                    font-size: 0.373rem;
                    font-weight: bold;
                    color: #E2335D;
                    line-height: 0.427rem;
                }
            }
        }
    }
</style>
